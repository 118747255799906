import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { useMerchantStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import { useQueryString } from 'components/QueryString'
import Card from 'components/card/Card'
import { FocusSpan, showDialog } from 'components/common/Dialog'
import { EditableStringDiv } from 'components/common/EditableStringDiv'
import { FirebaseDb, useFirebase } from 'components/common/Firebase'
import { RoundButton } from 'components/common/RoundButton'
import { useUserNullable } from 'components/common/UseUser'
import { CARD_CONFIG } from 'constants/common'
import useAuth from 'hooks/useAuth'
import { ReactComponent as FacebookSVG } from 'icons/facebook.svg'
import { ReactComponent as InstagramSVG } from 'icons/instagram.svg'
import { ReactComponent as LinkedInSVG } from 'icons/linkedIn.svg'
import { ReactComponent as LocationSVG } from 'icons/location.svg'
import { ReactNode, useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { useNavigate, useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { PendingScanLog, TokenTemplate } from '../../../../functions/src/data/common'
import { LoyaltyCardSheetV2 } from './LoyalCardSheet'

const MerchantAfterScan: React.FC = () => {
  const [creating, setCreating] = useState<boolean>(false)
  const { userInfo } = useAuth()
  const { id: merchantId, configId } = useParams()
  const navigate = useNavigate()
  const [instorescan] = useQueryString('instorescan', undefined)
  const [address] = useQueryString('address', undefined)

  const firebase = useFirebase(firebaseConfig)

  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const userCardStore = useUserCardsStore(user, firebaseDb)
  const merchantStore = useMerchantStore(merchantId, firebaseDb)

  const promptForEmail = async (
    explanation: (span: FocusSpan) => ReactNode,
    placeholderText: string,
  ) => {
    return await new Promise<string | undefined>((resolve, reject) => {
      showDialog<string>(
        {
          children: (FocusSpan, state, setState, triggerPositiveButton, focusPositiveButton) => {
            return (
              <>
                {explanation(FocusSpan)}
                <EditableStringDiv
                  autoFocus={true}
                  style={{ backgroundColor: '#d9e1e3', color: 'black' }}
                  placeholder={placeholderText}
                  inputMode={'email'}
                  onChange={setState}
                  onEnter={triggerPositiveButton}
                  aria-multiline={false}
                />
              </>
            )
          },
          positiveButtonProps: {
            text: 'OK',
            disabled: (state) => !state,
            onClicked: (state) => {
              if (state) {
                resolve(state)
                return true
              }
              return false
            },
          },
          negativeButtonProps: {
            text: 'CANCEL',
            onClicked: () => {
              resolve(undefined)
              return true
            },
          },
        },
        undefined,
      )
    })
  }
  const handleGetCards = async (configId: string) => {
    setCreating(true)

    let currentUser = user
    let email: string | undefined = currentUser?.email ?? undefined

    if (!email && currentUser) {
      email = (await firebaseDb.get(`users/${currentUser.uid}/email`)).val()
    }

    if (!email) {
      email = await promptForEmail(
        (FocusSpan) => (
          <p>
            Enter an email to help{' '}
            <FocusSpan>
              {merchantStore.type === 'SUCCESS' && merchantStore.merchantData.name}
            </FocusSpan>{' '}
            find your card
          </p>
        ),
        'Enter your email',
      )
    }

    if (email && !currentUser) {
      currentUser = (await loyaltyAuth.signInAnonymously()).user
    }

    if (email && currentUser && merchantStore.type === 'SUCCESS') {
      await firebaseDb.getRef(`users/${currentUser.uid}/email`).set(email)
      await merchantStore.addCard(currentUser, email, configId)
    }

    setCreating(false)
  }
  //todo this functional is using triggerByUid to log actions
  useEffect(() => {
    if (instorescan && merchantId && userInfo) {
      firebaseDb
        .getRef('/pendingScanLog')
        .push({ merchantId: merchantId, triggeredByUid: userInfo.uid } satisfies PendingScanLog)
        .then(() => {
          const url = new URL(location.toString())
          url.searchParams.delete('instorescan')
          navigate(url.pathname + url.search, { replace: true })
        })
    }
  }, [instorescan, navigate, merchantId, userInfo, firebaseDb])
  if (merchantStore.type === 'PENDING') {
    return <div className='text-center'>Loading....</div>
  }

  if (merchantStore.type === 'FAIL') {
    return <div className='text-center'>Data error</div>
  }

  if (merchantStore.type === 'MISSING') {
    return <></>
  }
  const { merchantData } = merchantStore
  const { cardConfigs } = merchantData
  const { tokens } = cardConfigs
  const { lineMax } = tokens.templates['default'] as TokenTemplate

  const userCardCollection: any =
    (userCardStore.type === 'SUCCESS' && merchantId && userCardStore.cardsData[merchantId]) || {}

  const cardConfig: string[] =
    (merchantStore.type === 'SUCCESS' && Object.keys(merchantData.cardConfigs)) || []

  return (
    <section className='mx-auto w-full max-w-[500px] bg-light-mb-main p-2.5'>
      <div className='space-y-4'>
        <div className='mt-6'>
          <p className='text-xl font-medium'>{merchantStore.merchantData.name}</p>
          <p className='text-sm text-light-secondary'>Message from the business</p>
        </div>
        <div className='h-40 w-full min-w-[340px] max-w-[450px] rounded-lg bg-[#ded2c5] p-6 shadow-lg'>
          <div className='grid h-full grid-cols-10'>
            <div className='col-span-6'>
              <div className='flex h-full items-end gap-x-2'>
                <FacebookSVG className='h-5 w-5' />
                <InstagramSVG className='h-5 w-5' />
                <LinkedInSVG className='h-5 w-5' />
              </div>
            </div>
            <div className='col-span-4'>
              <div className='flex h-max items-center gap-x-4'>
                <span>Find us</span>
                <LocationSVG className='h-5 w-5' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-8 flex flex-col items-center justify-center gap-4'>
        {cardConfig &&
          merchantId &&
          cardConfig
            .filter((configId) => !userCardCollection?.[configId])
            .map((item: string, idx: number) => (
              <RoundButton
                key={idx}
                className='px-4 text-csm shadow-md'
                aria-disabled={creating}
                onClick={() => handleGetCards(item)}>
                {(creating && 'Getting card...') ||
                  (item === CARD_CONFIG.tokens && `Get Buy ${lineMax} card`) ||
                  'Get Points card'}
              </RoundButton>
            ))}
      </div>
      <div className='mt-8 flex flex-col gap-4'>
        {userCardStore.type === 'SUCCESS' &&
          merchantStore.type === 'SUCCESS' &&
          cardConfig
            .filter((configId) => userCardCollection?.[configId])
            .map((item: string, idx: number) => (
              <LoyaltyCardSheetV2
                key={idx}
                cardId={userCardCollection[item].cardId}
                firebaseDb={firebaseDb}
              />
            ))}
      </div>
    </section>
  )
}
export default MerchantAfterScan
