import React, { useContext, useEffect } from 'react'
import { useUserNullable } from './components/common/UseUser'
import { useFirebase } from './components/common/Firebase'
import './App.css'

import 'react-circular-progressbar/dist/styles.css'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Flex, IconButton } from '@chakra-ui/react'
import DocumentTitle from 'react-document-title'
import { useQueryString } from './components/QueryString'
import { LoyaltyCardSheet } from './LoyaltyCardSheet'
import { firebaseConfig } from './services/FirebaseConfig'
import { FaHome } from 'react-icons/fa'
import { pwaContext } from './components/common/NotificationRegistrationProvider'

const LAST_URL_KEY = 'last-url'

export function CardPage() {
  const [merchantId] = useQueryString('merchant')
  const { id: cardId } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const { notificationRegisterer } = useContext(pwaContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (user && merchantId === user?.uid) {
      notificationRegisterer?.requestForNotifications()
    }
  }, [user, merchantId, notificationRegisterer])
  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <Flex
        direction={'column'}
        width={'100%'}
        height={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        overflowY={'auto'}>
        <Flex direction={'row'}>
          <IconButton
            onClick={() => navigate('/')}
            border={'none'}
            padding={10}
            borderRadius={5}
            // backgroundColor={'rgb(0,0,0,0)'}
            aria-label={'home'}
            icon={<FaHome />}
          />
          {user !== undefined && <SignIn />}
        </Flex>
        {user === undefined && 'Loading...'}
        {user === null && <Navigate to={`/merchant/${merchantId}`} />}
        {cardId && user !== null && (
          <LoyaltyCardSheet
            merchantId={merchantId}
            id={cardId}
            firebaseDb={firebaseDb}
            user={user}
          />
        )}
      </Flex>
    </DocumentTitle>
  )
}
