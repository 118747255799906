import clsx from 'clsx'

interface INotificationBoxProps {
  className?: string
}

const NotificationBox: React.FC<INotificationBoxProps> = ({ className }) => {
  return (
    <div className={clsx(className, 'w-full rounded bg-white p-4 shadow-md')}>
      <h2 className='mb-4 text-xl font-semibold'>Notifications</h2>
      {/* Notifications content */}
    </div>
  )
}

export default NotificationBox
