import { useMerchantStore } from 'UseMerchantStore'
import MerchantCard from 'components/card/MerchantCard'
import { CARD_CONFIG } from 'constants/common'
import { useMemo } from 'react'
import { ICard, LiveMerchantCardProps } from 'types/card.module'
import { MerchantEntry, TokenCardConfig } from '../../../../functions/src/data/common'

const LiveMerchantCard: React.FC<LiveMerchantCardProps> = ({
  className,
  merchantId,
  firebaseDb,
  onClick,
}) => {
  const merchantState = useMerchantStore(merchantId, firebaseDb)
  const merchantEntry =
    merchantState.type === 'SUCCESS' ? (merchantState.merchantData as MerchantEntry) : null

  const merchantConfig: ICard | null = useMemo(() => {
    if (!merchantEntry) return null

    const cardConfig = merchantEntry.cardConfigs[CARD_CONFIG.tokens] as TokenCardConfig
    const template = cardConfig.templates['default']
    const points =
      (template.lineMax && Array.from({ length: template.lineMax as number }, (_, i) => i + 1)) ||
      []

    return {
      logo: merchantEntry.logo,
      name: merchantEntry.name,
      primaryColor: template.primaryColor,
      secondaryColor: template.secondaryColor,
      isCreate: false,
      points: points,
    }
  }, [merchantEntry])

  if (!merchantConfig) {
    return <div>Loading...</div>
  }

  return (
    <MerchantCard
      onClick={() => onClick && onClick(merchantConfig)}
      merchantConfig={merchantConfig}
    />
  )
}
export default LiveMerchantCard
