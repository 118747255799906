import { COOKIES } from 'constants/common'
import { FirebaseError } from 'firebase/app'
import {
  AuthError,
  User,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { firebaseConfig } from 'services/FirebaseConfig'
import { clearCookie, getCookie, setCookie } from 'util/cookies'
import { decodeString, encodeString } from 'util/hash'
import { showDialog } from './common/Dialog'
import { getPreferredProvider, useFirebase } from './common/Firebase'
import { hydrateUserUI } from './common/UseFirebaseUi'
import SkeletonHome from './skeletons/HomeSkeleton'

interface ProtectedRouteProps {
  component: React.ComponentType<any>
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const firebase = useFirebase(firebaseConfig)
  const loyaltyAuth = firebase.auth
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  useEffect(() => {
    const getJWT = getCookie(COOKIES.token)
    const getUser = getCookie(COOKIES.users)
    if (!getJWT || !getUser) {
      const unsubscribe = loyaltyAuth.onAuthStateChanged(
        async (user: User | null) => {
          if (!user) {
            clearCookie(COOKIES.users)
            clearCookie(COOKIES.token)
            setIsAuthenticated(false)
            return
          }
          const userInfo = {
            name: user.displayName as string,
            email: user.email as string,
            uid: user.uid,
            isAnonymous: user.isAnonymous,
          }
          const token = await user.getIdToken()
          setCookie(COOKIES.token, encodeString(token), 1)
          setCookie(COOKIES.users, encodeString(JSON.stringify(userInfo)), 1)
          if (user.isAnonymous) {
            setIsAuthenticated(true)
            return
          }

          const hydratedUser = await hydrateUserUI(user, loyaltyAuth, 'welcomeMessage')
          if (hydratedUser) {
            setIsAuthenticated(true)
          }
        },
        (error) => {
          console.error(error)
        },
      )
      async function checkRedirectResult() {
        try {
          await loyaltyAuth.checkRedirectResult()
        } catch (e: any) {
          console.error(e)
          if (
            e instanceof FirebaseError &&
            e.code === 'auth/account-exists-with-different-credential'
          ) {
            const authError = e as AuthError
            showDialog({
              title: 'Oops!',
              children: (Red) => (
                <>
                  The email you used
                  {authError.customData.email ?
                    <>
                      , <Red>{authError.customData.email}</Red>,
                    </>
                  : undefined}{' '}
                  is associated with a different login service..
                  <br />
                  <br />
                  <Red>Please use the original login service or try a different email.</Red>
                </>
              ),
              positiveButtonProps: {
                text: 'OK',
                onClicked: async () => {
                  const email = authError.customData.email
                  if (!email) return true
                  const signInMethods: string[] = await fetchSignInMethodsForEmail(
                    loyaltyAuth.firebaseAuth,
                    email,
                  )
                  const preferredProvider = getPreferredProvider(signInMethods)
                  if (!preferredProvider?.provider) {
                    console.error('Could not find provider')
                    toast('Could not find original authentication provider. Ask for support')
                    return true
                  }
                  const result = await signInWithPopup(
                    loyaltyAuth.firebaseAuth,
                    preferredProvider.provider,
                  )
                  const credential = preferredProvider.credentialFromError(e)
                  if (result.user.email === email && credential) {
                    await linkWithCredential(result.user, credential)
                  }
                  return true
                },
              },
            })
          }
        }
      }

      checkRedirectResult()
      loyaltyAuth.signInIfEmailLink()
      return () => unsubscribe()
    } else {
      setIsAuthenticated(true)
    }
  }, [loyaltyAuth])

  if (isAuthenticated === null) {
    return <SkeletonHome />
  }
  return isAuthenticated ?
      <Component {...rest} />
    : <Navigate
        to={`/signin?redirect=${encodeURIComponent(location.pathname + location.search + location.hash)}`}
      />
}

export default ProtectedRoute
