import React, { PropsWithChildren, RefObject, useEffect, useRef } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: RefObject<HTMLDivElement>, onClickOutside?: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: PointerEvent) => {
      if (ref.current && event.target instanceof Element && ref.current.contains(event.target)) {
        return
      }
      onClickOutside?.()
    }
    // Bind the event listener
    document.addEventListener('pointerdown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props: PropsWithChildren<{ onClickOutside?: () => void }>) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props.onClickOutside)

  return <div ref={wrapperRef}>{props.children}</div>
}
