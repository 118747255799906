import { Flex, IconButton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { FaHome } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import './App.css'
import { useUserCardsStore } from './UseUserCardsStore'
import { useQueryString } from './components/QueryString'
import { useFirebase } from './components/common/Firebase'
import { useFirebaseUi } from './components/common/UseFirebaseUi'
import { firebaseConfig } from './services/FirebaseConfig'

export function SignInPage() {
  const { auth: loyaltyAuth, firebaseDb } = useFirebase(firebaseConfig)
  const [redirectLocation] = useQueryString('redirect', '/')
  const [hasUserData, setHasUserData] = useState(true)
  const { SignInForm, userSignedIn, errors } = useFirebaseUi({
    wrappedAuth: loyaltyAuth,
    linkAccounts: hasUserData,
    redirectUrl: redirectLocation,
    disableOptions: { facebook: true },
  })
  const results = useUserCardsStore(userSignedIn, firebaseDb)

  useEffect(() => {
    if (results.type === 'SUCCESS' && Object.keys(results.cardsData).length) {
      setHasUserData(true)
    } else {
      setHasUserData(false)
    }
  }, [results])

  const navigate = useNavigate()
  useEffect(() => {
    if (userSignedIn) {
      firebaseDb.get(`users/${userSignedIn.uid}`).then((user) => {
        if (!user.exists()) firebaseDb.getRef(`users/${userSignedIn.uid}/isMerchant`).set(false)
      })
      if (!userSignedIn.isAnonymous) navigate(redirectLocation ?? '/')
    }
  }, [userSignedIn, firebaseDb, navigate, redirectLocation])

  return (
    <DocumentTitle title={'Instant Loyalty - Sign in'}>
      <Flex
        direction={'column'}
        height={'100%'}>
        {userSignedIn && !userSignedIn?.isAnonymous && `Welcome ${userSignedIn.displayName}`}
        {errors && <p color={'black'}>{errors?.message}</p>}
        <Flex direction={'row'}>
          <IconButton
            onClick={() => navigate('/')}
            border={'none'}
            padding={10}
            borderRadius={5}
            // backgroundColor={'rgb(0,0,0,0)'}
            aria-label={'home'}
            icon={<FaHome />}
          />
        </Flex>
        <SignInForm />
      </Flex>
    </DocumentTitle>
  )
}
