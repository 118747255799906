import React, { CSSProperties, PropsWithChildren } from 'react'
import { Flex } from '@chakra-ui/react'

export function CardContainer({
  children,
  fontFamily = 'LeagueSpartan',
  style,
  onClick,
}: PropsWithChildren<{
  fontFamily?: string
  style?: CSSProperties
  onClick?: () => void
}>) {
  return (
    <Flex
      pointerEvents={'auto'}
      direction={'column'}
      maxWidth={'fit-content'}
      boxSizing={'border-box'}
      width={'100vw'}
      maxHeight={'100%'}
      color={'white'}
      padding={16}
      fontFamily={fontFamily}
      borderRadius={10}
      fontSize={20}
      gap={10}
      onClick={onClick}
      style={{ ...style, backgroundColor: style?.backgroundColor ?? 'black' }}>
      {children}
    </Flex>
  )
}
