import { WrappedAuth } from './Firebase'
import { RoundButton } from './RoundButton'
import { hydrateUserUI } from './UseFirebaseUi'
import { randomUUID } from './utils/randomUUID'
import { Flex } from '@chakra-ui/react'
import { User } from 'firebase/auth'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface UseLoginParams {
  auth: WrappedAuth
  allowAnonymous?: boolean
  welcomeMessage?: string
}

const BROWSER_ID_KEY = 'BROWSER_ID'

export function useBrowserId(localStorage: Storage) {
  const browserId = useMemo(() => {
    let storedId = localStorage.getItem(BROWSER_ID_KEY)

    if (!storedId) {
      storedId = randomUUID()
      localStorage.setItem(BROWSER_ID_KEY, storedId)
    }
    return storedId
  }, [localStorage])
  return { browserId }
}

export function useUser({ auth, welcomeMessage = 'Welcome!' }: UseLoginParams) {
  const { user, navigateToSignIn, SignIn } = useUserNullable({
    auth: auth,
    welcomeMessage,
  })
  return useMemo(() => {
    return { user: user ?? undefined, navigateToSignIn, SignIn }
  }, [user, navigateToSignIn, SignIn])
}

export function useUserNullable({ auth, welcomeMessage = 'Welcome!' }: UseLoginParams) {
  const [user, setUser] = useState<User | null>()
  const location = useLocation()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (user: User | null) => {
        if (!user) {
          setUser(null)
          return
        }

        if (user.isAnonymous) {
          setUser(user)
          return
        }

        const hydratedUser = await hydrateUserUI(user, auth, welcomeMessage)
        setUser(hydratedUser)
      },
      (error) => {
        console.log(error)
      },
    )
    return () => unsubscribe()
  }, [auth, welcomeMessage])
  const navigate = useNavigate()
  const signOut = useCallback(async () => {
    await auth.signOut()
  }, [auth])
  const navigateToSignIn = useCallback(async () => {
    navigate(
      '/signin?redirect=' + encodeURIComponent(location.pathname + location.search + location.hash),
    )
  }, [location, navigate])
  const SignIn = useCallback(
    (props: { style?: CSSProperties; className?: string; displayStyle?: 'large' | 'small' }) => {
      return (
        <Flex
          display={'flex'}
          color={'white'}
          backgroundColor={'rgb(0,0,0,0)'}
          pointerEvents={'auto'}
          style={{ ...props.style }}
          alignItems={'center'}
          padding={'0 8px'}
          fontFamily={'LeagueSpartan, sans-serif'}
          gap={'4px'}
          role={'group'}>
          {user && !user.isAnonymous ?
            <>
              <span>Welcome, {user.displayName || ''}</span>
              <RoundButton
                className='group-hover:opacity-100 group-hover:transform-none group-hover:max-w-999 group-hover:filter-none
                  hover:bg-playback-crimson hover:filter-none max-w-0 transform -translate-x-full scale-x-0 opacity-0
                  filter grayscale text-[0.8em] transition-all duration-200 ease-in-out h-[2.5em]'
                backgroundColor={'rgba(177, 182, 183, 0.34)'}
                color={'white'}
                onClick={signOut}
                alt={'Log out'}
                displayStyle={'small'}>
                Log out
              </RoundButton>
            </>
          : <RoundButton
              alt={'Sign in'}
              backgroundColor={'rgba(177, 182, 183, 0.34)'}
              className='group-hover:filter-none hover:bg-playback-crimson hover:filter-none transition-all duration-200
                ease-in-out h-[2.5em] text-[0.8em]'
              displayStyle={'small'}
              color={'white'}
              onClick={navigateToSignIn}>
              Sign in
            </RoundButton>
          }
        </Flex>
      )
    },
    [user, navigateToSignIn, signOut],
  )
  return { SignIn, navigateToSignIn, user }
}
