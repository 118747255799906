import clsx from 'clsx'
import { cn } from 'components/common/utils/tailwindUtils'
import { TwLableForm } from 'components/modules/twLayout.module'
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg'
import { ReactComponent as CheckSVG } from 'icons/check_icon.svg'
import { useMemo, useState } from 'react'

type option = { label: string; value: string }
interface DropdownSelectProps {
  options: option[]
  label: string
  value: string
  onChange: (value: string) => void
  errors?: string
  classNameLabel?: string
  typeLable?: 'left' | 'center'
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  label,
  value,
  onChange,
  errors,
  classNameLabel,
  typeLable = 'left',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const renderLabelStyle = useMemo(() => {
    return {
      center: 'text-center tracking-widest text-xl text-light-secondary',
      left: 'text-left text-black',
    }[typeLable]
  }, [typeLable])

  const handleSelect = (selectedValue: string) => {
    onChange(selectedValue)
    setIsOpen(false)
  }

  return (
    <div className='relative inline-block w-full'>
      <TwLableForm className={cn(classNameLabel, renderLabelStyle)}>{label}</TwLableForm>
      <button
        type='button'
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(
          `relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left
          shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500`,
          {
            'border-red-500': errors,
          },
        )}>
        <span className='block truncate text-sm'>
          {options.find((option) => option.value == value)?.label || label}
        </span>
        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ArrowLeft
            className={clsx('h-5 w-5', { '-rotate-90': !!isOpen, 'rotate-180': !isOpen })}
          />
        </span>
      </button>
      {isOpen && (
        <div className='absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg'>
          <ul
            className='max-h-60 overflow-auto rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none
              sm:text-sm'>
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => handleSelect(option.value)}
                className='relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-light-primary
                  hover:text-white'>
                <span
                  className={clsx(
                    `block truncate ${option.value == value ? 'font-semibold' : 'font-normal'}`,
                  )}>
                  {option.label}
                </span>
                {option.value === value && (
                  <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-light-primary'>
                    <CheckSVG />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {errors && <p className='mt-2 text-sm text-red-600'>{errors}</p>}
    </div>
  )
}

export default DropdownSelect
