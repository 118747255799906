export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
// credit: Typescript documentation, src
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName] // o[propertyName] is of type T[K]
}

export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key as K))) as Omit<T, K>

export function deepCopy<T>(obj: T): T {
  let copy: any

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy as T
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = []
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i])
    }
    return copy as T
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {}
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr])
    }
    return copy as T
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]
declare global {
  interface Array<T> {
    mapProp<K extends keyof T>(key: K): T[K][]

    any(this: T[], predicate: (value: T) => boolean): boolean

    all(this: T[], predicate: (value: T) => boolean): boolean

    none(this: T[], predicate: (value: T) => boolean): boolean

    findLast(this: T[], predicate: (value: T, index: number, array: T[]) => boolean): T | undefined

    distinctBy<K>(this: T[], by?: (value: T) => K): T[]

    distinct(this: T[]): T[]

    intersection(this: T[], that: any[]): T[]

    excludeAll(this: T[], that: any[]): T[]

    excludeSet(this: T[], that: Set<any>): T[]

    hasIntersection(this: T[], that: any[]): boolean

    groupBy<T, K>(this: T[], by: (value: T) => K): Map<K, T[]>

    getOrNull(this: T[], index: number): T | undefined

    firstOrNull(this: T[]): T | undefined

    lastOrNull(this: T[]): T | undefined

    minOrNull<T extends number>(this: T[]): number | undefined

    minByOrNull<T>(this: T[], mapper: (value: T) => number): T | undefined

    maxByOrNull<T>(this: T[], mapper: (value: T) => number): T | undefined

    minByPropOrNull<K extends keyof T>(this: T[], key: K): T | undefined

    maxByPropOrNull<K extends keyof T>(this: T[], key: K): T | undefined

    maxOrNull<T extends number>(this: T[]): number | undefined

    medianOrNull<T extends number>(this: T[]): number | undefined

    avgOrNull<T extends number>(this: T[]): number | undefined

    sum<T extends number>(this: T[]): number

    mapNotNull<T, K>(
      this: T[],
      mapper: (value: T, index: number, array: T[]) => K | undefined | null,
    ): NonNullable<K>[]

    filterNotNull<T>(this: (T | undefined | null)[]): T[]

    remove<T>(this: T[], value: T): T | undefined

    exclude<T>(this: T[], value: T): T[]

    orderByDesc<T>(this: T[], mapper: ((value: T) => number) | ((value: T) => string)): T[]

    orderBy<T>(this: T[], mapper: ((value: T) => number) | ((value: T) => string)): T[]

    orderByProp<T>(this: T[], propName: NumberOrStringPropertyNames<T>): T[]

    reducePartial<U extends object>(
      this: T[],
      fn: (
        previousValue: Partial<U>,
        currentValue: T,
        currentIndex: number,
        array: T[],
      ) => Partial<U>,
      initial?: Partial<U>,
    ): U
    reducePartial<U extends Record<any, any>>(
      this: T[],
      fn: (
        previousValue: Partial<U>,
        currentValue: T,
        currentIndex: number,
        array: T[],
      ) => Partial<U>,
      initial?: Partial<U>,
    ): U
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.reducePartial = function <T extends { [key: string]: any }, U>(
  this: T[],
  fn: (previousValue: Partial<U>, currentValue: T, currentIndex: number, array: T[]) => Partial<U>,
  initial?: Partial<U>,
): U {
  return this.reduce<Partial<U>>(fn, initial ?? {}) as unknown as U
}

// eslint-disable-next-line no-extend-native
Array.prototype.findLast = function <T>(
  this: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
): T | undefined {
  for (let i = this.length - 1; i >= 0; i--) {
    if (predicate(this[i], i, this)) {
      return this[i]
    }
  }
  return undefined
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinctBy = function <T, K>(this: T[], by: (value: T) => K): T[] {
  const distinctBy: Set<K> = new Set()
  const results: T[] = []

  for (const t of this) {
    const byValue = by(t)
    if (!distinctBy.has(byValue)) {
      distinctBy.add(byValue)
      results.push(t)
    }
  }
  return results
}
// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function <T>(this: T[]): T[] {
  return this.distinctBy((it) => it)
}
// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function <T, K>(this: T[], by: (value: T) => K): Map<K, T[]> {
  const groupBy: Map<K, T[]> = new Map()
  for (const t of this) {
    const byValue = by(t)
    if (!groupBy.has(byValue)) {
      groupBy.set(byValue, [t])
    } else {
      groupBy.get(byValue)?.push(t)
    }
  }
  return groupBy
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderBy = function <T>(
  this: T[],
  mapper: ((value: T) => number) | ((value: T) => string),
): T[] {
  return new Array<T>(...this).sort((a, b) => {
    const mappedA = mapper(a)
    const mappedB = mapper(b)

    return typeof mappedA === 'number' ?
        mappedA - (mappedB as number)
      : mappedA.localeCompare(mappedB as string)
  })
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderByDesc = function <T>(
  this: T[],
  mapper: ((value: T) => number) | ((value: T) => string),
): T[] {
  return new Array<T>(...this).sort((a, b) => {
    const mappedA = mapper(a)
    const mappedB = mapper(b)

    return typeof mappedB === 'number' ?
        mappedB - (mappedA as number)
      : mappedB.localeCompare(mappedA as string)
  })
}

type NumberOrStringPropertyNames<T> = {
  [K in keyof T]: T[K] extends number | string ? K : never
}[keyof T]
// eslint-disable-next-line no-extend-native
Array.prototype.orderByProp = function <T>(
  this: T[],
  propName: NumberOrStringPropertyNames<T>,
): T[] {
  return this.orderBy((it) => it[propName] as any)
}
// eslint-disable-next-line no-extend-native
Array.prototype.intersection = function <T>(this: T[], that: any[]): T[] {
  const set1 = new Set(this)
  const results: T[] = []

  // Check if any element in list2 is also present in set1
  for (const item of that) {
    if (set1.has(item)) {
      results.push(item)
    }
  }

  return results
}
// eslint-disable-next-line no-extend-native
Array.prototype.excludeAll = function <T>(this: T[], that: any[]): T[] {
  const set1 = new Set(this)

  // Check if any element in list2 is also present in set1
  for (const item of that) {
    if (set1.has(item)) {
      set1.delete(item)
    }
  }

  return Array.from(set1)
}

// eslint-disable-next-line no-extend-native
Array.prototype.excludeSet = function <T>(this: T[], that: Set<any>): T[] {
  return this.excludeAll(Array.from(that))
}

// eslint-disable-next-line no-extend-native
Array.prototype.hasIntersection = function <T>(this: T[], that: any[]): boolean {
  const set1 = new Set(this)

  // Check if any element in list2 is also present in set1
  for (const item of that) {
    if (set1.has(item)) {
      return true
    }
  }

  return false
}

// eslint-disable-next-line no-extend-native
Array.prototype.remove = function <T>(this: T[], value: T): T | undefined {
  const index = this.indexOf(value)
  if (index === -1) return undefined
  const removed = this[index]
  delete this[index]
  return removed
}

// eslint-disable-next-line no-extend-native
Array.prototype.exclude = function <T>(this: T[], value: T): T[] {
  return this.filter((it) => it !== value)
}

// eslint-disable-next-line no-extend-native
Array.prototype.mapNotNull = function <T, K>(
  this: T[],
  mapper: (value: T, index: number, array: T[]) => K | undefined | null,
): NonNullable<K>[] {
  const mappedResults: NonNullable<K>[] = []
  if (this.length === 0) return mappedResults as NonNullable<K>[]
  for (let tsKey = 0; tsKey < this.length; tsKey++) {
    const mapped = mapper(this[tsKey], tsKey, this)
    if (mapped !== undefined && mapped !== null) {
      mappedResults.push(mapped)
    }
  }
  return mappedResults
}

// eslint-disable-next-line no-extend-native
Array.prototype.filterNotNull = function <T>(this: (T | undefined | null)[]): NonNullable<T>[] {
  return this.mapNotNull((it) => it)
}

// eslint-disable-next-line no-extend-native
Array.prototype.maxOrNull = function <T extends number>(this: T[]): number | undefined {
  if (this.length === 0) return undefined
  let max = this.firstOrNull()
  if (max === undefined) return undefined
  for (let tsKey = 0; tsKey < this.length; tsKey++) {
    if (this[tsKey] > max) {
      max = this[tsKey]
    }
  }
  return max
}

// eslint-disable-next-line no-extend-native
Array.prototype.maxByOrNull = function <T>(this: T[], mapper: (value: T) => number): T | undefined {
  if (this.length === 0) return undefined
  let max = this.firstOrNull()
  if (max === undefined) return undefined
  let maxValue: number = mapper(max)
  for (let tsKey = 0; tsKey < this.length; tsKey++) {
    const value = mapper(this[tsKey])
    if (value > maxValue) {
      max = this[tsKey]
      maxValue = value
    }
  }
  return max
}

// eslint-disable-next-line no-extend-native
Array.prototype.minByOrNull = function <T>(this: T[], mapper: (value: T) => number): T | undefined {
  if (this.length === 0) return undefined
  let min = this.firstOrNull()
  if (min === undefined) return undefined
  let minValue: number = mapper(min)
  for (let tsKey = 0; tsKey < this.length; tsKey++) {
    const value = mapper(this[tsKey])
    if (value < minValue) {
      min = this[tsKey]
      minValue = value
    }
  }
  return min
}
// eslint-disable-next-line no-extend-native
Array.prototype.maxByPropOrNull = function <T, K extends keyof T>(
  this: T[],
  key: K,
): T | undefined {
  return this.maxByOrNull((it) => it[key] as number)
}

// eslint-disable-next-line no-extend-native
Array.prototype.minByPropOrNull = function <T, K extends keyof T>(
  this: T[],
  key: K,
): T | undefined {
  return this.minByOrNull((it) => it[key] as number)
}

// eslint-disable-next-line no-extend-native
Array.prototype.medianOrNull = function <T extends number>(this: T[]): number | undefined {
  if (this.length === 0) return undefined

  this.sort(function (a, b) {
    return a - b
  })

  const half = Math.floor(this.length / 2)

  if (this.length % 2) return this[half]

  return (this[half - 1] + this[half]) / 2.0
}

// eslint-disable-next-line no-extend-native
Array.prototype.avgOrNull = function <T extends number>(this: T[]): number | undefined {
  if (this.length === 0) return undefined

  return this.sum() / this.length
}

// eslint-disable-next-line no-extend-native
Array.prototype.sum = function <T extends number>(this: T[]): number {
  let sum = 0
  for (let i = 0; i < this.length; i++) {
    sum += this[i]
  }
  return sum
}
// eslint-disable-next-line no-extend-native
Array.prototype.minOrNull = function <T extends number>(this: T[]): number | undefined {
  if (this.length === 0) return undefined
  let min = this.firstOrNull()
  if (min === undefined) return undefined
  for (let tsKey = 0; tsKey < this.length; tsKey++) {
    if (this[tsKey] < min) {
      min = this[tsKey]
    }
  }
  return min
}

// eslint-disable-next-line no-extend-native
Array.prototype.lastOrNull = function <T>(this: T[]): T | undefined {
  return this.at(this.length - 1)
}

// eslint-disable-next-line no-extend-native
Array.prototype.firstOrNull = function <T>(this: T[]): T | undefined {
  return this.at(0)
}

// eslint-disable-next-line no-extend-native
Array.prototype.getOrNull = function <T>(this: T[], index: number): T | undefined {
  return (index >= 0 && index < this.length && this.at(index)) || undefined
}
// eslint-disable-next-line no-extend-native
Array.prototype.mapProp = function <T, K extends keyof T>(this: T[], key: K): T[K][] {
  return this.map((i) => i[key])
}
// eslint-disable-next-line no-extend-native
Array.prototype.any = function <T>(predicate: (value: T) => boolean): boolean {
  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i])) return true
  }
  return false
}
// eslint-disable-next-line no-extend-native
Array.prototype.all = function <T>(this: T[], predicate: (value: T) => boolean): boolean {
  for (let i = 0; i < this.length; i++) {
    if (!predicate(this[i])) return false
  }
  return true
}
// eslint-disable-next-line no-extend-native
Array.prototype.none = function <T>(this: T[], predicate: (value: T) => boolean): boolean {
  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i])) return false
  }
  return true
}

export function mergeSortedLists<T>(arr1: T[], arr2: T[], orderBy: (a: T) => number): T[] {
  const merged: T[] = []
  let i = 0,
    j = 0

  while (i < arr1.length && j < arr2.length) {
    if (orderBy(arr1[i]) - orderBy(arr2[j]) <= 0) {
      merged.push(arr1[i])
      i++
    } else {
      merged.push(arr2[j])
      j++
    }
  }

  while (i < arr1.length) {
    merged.push(arr1[i])
    i++
  }

  while (j < arr2.length) {
    merged.push(arr2[j])
    j++
  }

  return merged
}
