import { Box } from '@chakra-ui/react'
import QRCode from 'react-qr-code'
import { CardContainer } from './components/CardContainer'

export function BrandedQRCard(props: {
  qrUrl: string
  logo: string | undefined
  fontFamily: string
  cardTitle: string | undefined
  theme: { backgroundColor: string }
  onFlipClicked?: () => void
}) {
  return (
    <CardContainer
      style={{
        boxShadow: '0 0 20px 20px rgb(255,255,255,0.5), 0 0 20px 10px rgb(0,0,0,0.3)',
        backgroundColor: props.theme.backgroundColor,
      }}
      fontFamily={props.fontFamily}>
      <Box
        flexShrink={1}
        flexGrow={1}
        flexBasis={'auto'}
        height={'70%'}
        aspectRatio={1 / 1}
        padding={4}
        boxSizing={'border-box'}
        width={'fit-content'}
        backgroundColor={'white'}>
        <QRCode
          value={props.qrUrl}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
      <div className='text-center'>
        <span className='text-2xl font-semibold uppercase'>Scan me</span>
      </div>
    </CardContainer>
  )
}
