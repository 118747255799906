import { useMerchantStore } from 'UseMerchantStore'
import { useFirebase } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import { firebaseConfig } from 'services/FirebaseConfig'
import { IAuth } from 'stores/authStore'
import { MerchantEntry } from '../../functions/src/data/common'

type UseMerchantDataReturnType =
  | {
      merchant: MerchantEntry
      addCard(user: User, email: string, cardConfigId: string): Promise<string | null>
    }
  | null
  | undefined

export const useMerchantData = (uid: string, isLoading = true): UseMerchantDataReturnType => {
  const { firebaseDb } = useFirebase(firebaseConfig)

  // Ensure hooks are called unconditionally
  const merchant = useMerchantStore(uid ?? '', firebaseDb)

  if (!uid) {
    return null
  }

  if (merchant.type === 'PENDING') {
    return undefined
  }

  if (merchant.type === 'SUCCESS') {
    return {
      merchant: merchant.merchantData as MerchantEntry,
      addCard: merchant.addCard,
    }
  } else {
    return null
  }
}

type UseMerchantListReturnType =
  | {
      merchant: MerchantEntry
      addCard(user: User, email: string, cardConfigId: string): Promise<string | null>
    }
  | null
  | undefined

// export const userMerchantList = (uid: string, isLoading = true): UseMerchantListReturnType => {
//   const { firebaseDb } = useFirebase(firebaseConfig)

//   // Ensure hooks are called unconditionally
//   const merchant = useMerchantStore(uid ?? '', firebaseDb)

//   if (!uid) {
//     return null
//   }

//   if (merchant.type === 'PENDING') {
//     return undefined
//   }

//   if (merchant.type === 'SUCCESS') {
//     return {
//       merchant: merchant.merchantData as MerchantEntry,
//       addCard: merchant.addCard,
//     }
//   } else {
//     return null
//   }
// }
