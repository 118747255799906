import { showDialog } from './Dialog'
import { EditableStringDiv } from './EditableStringDiv'
import { getPreferredProvider, WrappedAuth } from './Firebase'
import { useMutableRef } from './utils/reactUtils'
import { Button } from '@chakra-ui/react'
import { FirebaseError } from 'firebase/app'
import {
  AuthError,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithPopup,
  updateProfile,
  User,
} from 'firebase/auth'
import 'firebaseui/dist/firebaseui.css'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export function useFirebaseUi({
  wrappedAuth,
  linkAccounts,
  redirectUrl,
  welcomeMessage = 'Welcome!',
  disableOptions,
}: {
  wrappedAuth: WrappedAuth
  linkAccounts: boolean
  redirectUrl: string | undefined
  welcomeMessage?: string
  disableOptions?: { facebook?: boolean; google?: boolean; email?: boolean }
}) {
  const [userSignedIn, setUserSignedIn] = useState<User | undefined>(undefined)
  const [errors, setErrors] = useState<Error>()
  const [inProgress, setInProgress] = useState<boolean>(true)
  useEffect(() => {
    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = wrappedAuth.onAuthStateChanged(
      async (user) => {
        setErrors(undefined)
        if (!user) {
          setUserSignedIn(undefined)
          setInProgress(false)
          return
        }

        if (user.isAnonymous) {
          if (linkAccounts) {
            const newUser = await wrappedAuth.linkIfEmailLink(user)
            setInProgress(false)
            setUserSignedIn(newUser?.user ?? user)
            return
          }
          setInProgress(false)
          setUserSignedIn(user)
          return
        }

        const hydratedUser = await hydrateUserUI(user, wrappedAuth, welcomeMessage)
        setInProgress(false)
        setUserSignedIn(hydratedUser)
      },
      (error) => {
        setErrors(error)
        console.log(error)
      },
    )

    async function checkRedirectResult() {
      try {
        await wrappedAuth.checkRedirectResult()
      } catch (e: any) {
        console.error(e)
        if (
          e instanceof FirebaseError &&
          e.code === 'auth/account-exists-with-different-credential'
        ) {
          const authError = e as AuthError
          showDialog({
            title: 'Oops!',
            children: (Red) => (
              <>
                The email you used
                {authError.customData.email ?
                  <>
                    , <Red>{authError.customData.email}</Red>,
                  </>
                : undefined}{' '}
                is associated with a different login service..
                <br />
                <br />
                <Red>Please use the original login service or try a different email.</Red>
              </>
            ),
            positiveButtonProps: {
              text: 'OK',
              onClicked: async () => {
                const email = authError.customData.email
                if (!email) return true
                const signInMethods: string[] = await fetchSignInMethodsForEmail(
                  wrappedAuth.firebaseAuth,
                  email,
                )
                const preferredProvider = getPreferredProvider(signInMethods)
                if (!preferredProvider?.provider) {
                  console.error('Could not find provider')
                  toast('Could not find original authentication provider. Ask for support')
                  return true
                }
                const result = await signInWithPopup(
                  wrappedAuth.firebaseAuth,
                  preferredProvider.provider,
                )
                const credential = preferredProvider.credentialFromError(e)
                if (result.user.email === email && credential) {
                  await linkWithCredential(result.user, credential)
                }
                return true
              },
            },
          })
        }
      }
    }

    checkRedirectResult()
    wrappedAuth.signInIfEmailLink()
    return () => unregisterAuthObserver()
  }, [wrappedAuth, linkAccounts, welcomeMessage])

  const signInFacebook = useCallback(async () => {
    setInProgress(true)
    await wrappedAuth.signInWithFacebook()
    setInProgress(false)
  }, [wrappedAuth])
  const signInGoogle = useCallback(async () => {
    setInProgress(true)
    await wrappedAuth.signInWithGoogle()
    setInProgress(false)
  }, [wrappedAuth])
  const [emailStateRef, setEmail] = useMutableRef<string>()

  const signInEmail = useCallback(async () => {
    showDialog({
      title: `Sign in with email`,
      children: (FocusSpan, state, setState, triggerPositiveButton, focusPositiveButton) => {
        return (
          <EditableStringDiv
            placeholder={'Enter an email to continue'}
            inputMode={'email'}
            onChange={setEmail}
            translate={'no'}
            onEnter={(changedValue) => triggerPositiveButton(changedValue)}
            aria-multiline={false}
            blurOnEnter={true}
          />
        )
      },
      positiveButtonProps: {
        text: 'Send email link',
        onClicked: async () => {
          if (emailStateRef.current) {
            await wrappedAuth.sendSignInEmail(emailStateRef.current.toString(), redirectUrl)
            showDialog({
              title: `Link sent`,
              children: (Red) => {
                return (
                  <>
                    <Red>We&apos;ve sent a link to your email.</Red> You&apos;ll need to open the
                    link in the email continue.
                  </>
                )
              },
            })
            return true
          }
          return false
        },
      },
      negativeButtonProps: {
        text: 'Cancel',
        onClicked: async () => {
          emailStateRef.current = undefined
          return true
        },
      },
    })
  }, [emailStateRef, wrappedAuth, setEmail, redirectUrl])
  const disableButtons = inProgress
  const Form = useCallback(
    () => (
      <ul className='firebaseui-idp-list'>
        {!disableOptions?.facebook && (
          <li className='firebaseui-list-item'>
            <LoginButton
              shortText={'Facebook'}
              longText={'Sign in with Facebook'}
              onClick={signInFacebook}
              disabled={disableButtons}
              backgroundColor={'#3b5998'}
              icon={'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg'}
            />
          </li>
        )}
        {!disableOptions?.google && (
          <li className='firebaseui-list-item'>
            <LoginButton
              shortText={'Google'}
              longText={'Sign in with Google'}
              onClick={signInGoogle}
              disabled={disableButtons}
              backgroundColor={'#ffffff'}
              color={'#757575'}
              icon={'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'}
            />
          </li>
        )}
        {!disableOptions?.email && (
          <li className='firebaseui-list-item'>
            <LoginButton
              shortText={'Email'}
              longText={'Sign in with Email'}
              onClick={signInEmail}
              backgroundColor={'#db4437'}
              disabled={disableButtons}
              icon={'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg'}
            />
          </li>
        )}
        <li
          className='firebaseui-tos'
          style={{ textAlign: 'center' }}>
          By continuing, you are indicating that you accept our{' '}
          <a
            className='firebaseui-link'
            href='/terms-of-service.html'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            className='firebaseui-link'
            href='/privacy.html'>
            Privacy Policy
          </a>
        </li>
      </ul>
    ),
    [disableButtons, signInGoogle, signInFacebook, signInEmail, disableOptions],
  )
  return { SignInForm: Form, userSignedIn, errors }
}

function LoginButton(props: {
  onClick: () => void
  backgroundColor: string
  longText: string
  shortText: string
  icon: string
  providerId?: string
  disabled?: boolean
  color?: string
}) {
  return (
    <Button
      className='firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password
        firebaseui-id-idp-button'
      data-provider-id={props.providerId}
      style={{ backgroundColor: props.backgroundColor }}
      data-upgraded=',MaterialButton'
      isDisabled={props.disabled}
      justifyContent={'flex-start'}
      _disabled={{ opacity: '60%' }}
      onClick={props.disabled ? undefined : props.onClick}>
      <span className='firebaseui-idp-icon-wrapper'>
        <img
          className='firebaseui-idp-icon'
          alt=''
          src={props.icon}
        />
      </span>
      <span
        className='firebaseui-idp-text firebaseui-idp-text-long'
        style={{ color: props.color }}>
        {props.longText}
      </span>
      <span
        className='firebaseui-idp-text firebaseui-idp-text-short'
        style={{ color: props.color }}>
        {props.shortText}
      </span>
    </Button>
  )
}

export async function hydrateUserUI(user: User, auth: WrappedAuth, welcomeMessage: string) {
  const hydratedUser = await auth.hydrateProfileIfRequired(user)
  if (hydratedUser && !hydratedUser.displayName) {
    await showDialog<string>({
      title: welcomeMessage,
      children: (Red, state, setState, triggerPositiveButton) => {
        return (
          <>
            <Red>We&apos;re excited to have you here.</Red>
            <br />
            <br /> To make your experience more personal, could you please share your name with us?
            Just type it below, and we&apos;ll get started.
            <br />
            <br />
            <EditableStringDiv
              placeholder={'Enter your name here'}
              blurOnEnter={true}
              translate={'no'}
              onChange={setState}
              onEnter={triggerPositiveButton}></EditableStringDiv>
          </>
        )
      },
      user_dismissable: false,
      positiveButtonProps: {
        text: 'Save',
        onClicked: async (suggestedName) => {
          await updateProfile(hydratedUser, { displayName: suggestedName })
          return true
        },
      },
    })
    await auth.firebaseAuth.updateCurrentUser(hydratedUser)
    return hydratedUser
  }
  return hydratedUser
}
