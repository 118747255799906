import { HoverableBox, HoverableImage } from '../UseHover'
import { BoxProps } from '@chakra-ui/react'
import React, { CSSProperties } from 'react'

export type IconInfo = string | { src: string; filter?: string; opacity?: string }

export interface BackgroundIconProps {
  height?: number | string
  width?: number | string
  src?: IconInfo
  alt?: string
  disableContextMenu?: boolean
  hover?: CSSProperties
}

/**
 * For when we really don't want context menus
 * @param props
 * @constructor
 */
export function BackgroundIcon(props: BackgroundIconProps & BoxProps) {
  const { height, width, src, alt, disableContextMenu, hover, ...boxProps } = props
  if (!props.disableContextMenu)
    return (
      <>
        {typeof src === 'string' && (
          <HoverableImage
            hover={hover}
            draggable={false}
            {...boxProps}
            src={src}
            height={height}
            width={width}
            alt={props.alt}
          />
        )}

        {typeof src !== 'string' && (
          <HoverableImage
            hover={hover}
            draggable={false}
            {...boxProps}
            src={src?.src}
            opacity={src?.opacity}
            filter={src?.filter}
            height={height}
            width={width}
            alt={props.alt}
          />
        )}
      </>
    )
  return (
    <HoverableBox
      {...boxProps}
      hover={hover}
      style={{
        height: props.height ?? props.width,
        width: props.width ?? props.height,
        ...props.style,
      }}>
      {typeof src === 'string' && (
        <div
          style={{
            backgroundImage: props.src && `url('${props.src}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        />
      )}

      {typeof src !== 'string' && (
        <div
          style={{
            backgroundImage: src && `url('${src.src}')`,
            filter: src?.filter,
            opacity: src?.opacity,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </HoverableBox>
  )
}
