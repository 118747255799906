import MbStickyFooter from 'components/footer/MbStickyFooter'
import Header from 'components/header'
import { LayoutProps } from './types'

// const Wrapper = tw.div`bg-white text-emerald-400`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='relative flex h-full w-full flex-col overflow-y-auto'>
      <Header />
      <div className='h-[calc(100vh-40px)] lg:h-full'>{children}</div>
      <MbStickyFooter />
    </div>
  )
}

export default Layout
