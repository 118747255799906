import { Flex } from '@chakra-ui/react'
import React, { CSSProperties, PropsWithChildren, ReactNode, createRef } from 'react'
import { BackgroundIcon } from './BackgroundIcon'
import { SquashingBox } from './SquashingBox'
import { cn } from './utils/tailwindUtils'

type FlatRoundButtonProps = PropsWithChildren & {
  className?: string
  displayStyle?: 'large' | 'small'
  squashedChildrenFallback?: ReactNode[]
  backgroundColor?: CSSProperties['backgroundColor']
  color?: CSSProperties['color']
  padding?: CSSProperties['padding']
  style?: CSSProperties
  onClick?: (e: React.MouseEvent) => void
  translate?: 'no' | 'yes'
  tabIndex?: number
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  ['aria-disabled']?: boolean
} & (
    | {
        icon: string | ReactNode
        iconInvert?: boolean
        alt: string
      }
    | {
        icon?: undefined
        iconInvert?: boolean
        alt?: string
      }
  )

export class RoundButton extends React.Component<FlatRoundButtonProps> {
  ref = createRef<SquashingBox>()

  hasFocus = () => {
    return document.activeElement === this.ref.current
  }

  blur = () => {
    return this.ref.current?.blur()
  }
  focus = () => {
    return this.ref.current?.focus()
  }
  contentRef = createRef<HTMLDivElement>()

  render() {
    const {
      icon,
      alt,
      displayStyle = 'large',
      iconInvert,
      squashedChildrenFallback,
      ...boxProps
    } = this.props

    const mapChildren = (child: ReactNode): ReactNode => {
      return (
        <Flex
          alignItems={'center'}
          className={`${displayStyle !== 'small' ? 'gap-[5px]' : undefined} `}
          transition={'all 200ms ease-in-out'}>
          {icon && typeof icon === 'string' && (
            <BackgroundIcon
              draggable={false}
              src={icon}
              width={'25px'}
              objectFit={'contain'}
              style={{
                filter: iconInvert ? `invert()` : undefined,
              }}
              className={`round-button-icon${displayStyle === 'large' ? '' : 'small'}`}
              height={'1.5em'}
              alt={alt}
            />
          )}
          {icon && typeof icon !== 'string' && icon}
          {child}
        </Flex>
      )
    }

    return (
      <SquashingBox
        ref={this.ref}
        fontFamily={'LeagueSpartan, sans-serif'}
        {...boxProps}
        style={{
          fontVariationSettings: "'wght' 500",
          backgroundColor: boxProps.backgroundColor,
          color: boxProps.color,
          padding: boxProps.padding ?? (displayStyle === 'small' ? '8px 10px' : undefined),
          ...boxProps.style,
        }}
        childrenArray={squashedChildrenFallback?.map(mapChildren)}
        className={cn(
          `pointer-events-auto flex h-[40px] cursor-pointer items-center gap-[4px] whitespace-nowrap
          rounded-full bg-white p-[8px] text-sm text-zinc-900 transition-all hover:contrast-50 hover:filter
          disabled:pointer-events-none disabled:cursor-default disabled:opacity-30
          aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:opacity-30`,
          this.props.className,
        )}>
        {mapChildren(this.props.children)}
      </SquashingBox>
    )
  }
}
