import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { COOKIES } from 'constants/common'
import useGreeting from 'hooks/useGreeting'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { clearCookie } from 'util/cookies'

const Header: React.FC = () => {
  const firebase = useFirebase(firebaseConfig)
  const loyaltyAuth = firebase.auth

  const { user, SignIn } = useUserNullable({ auth: loyaltyAuth })
  const navigator = useNavigate()
  const greeting = useGreeting()

  const signOut = useCallback(async () => {
    await loyaltyAuth.signOut()
    clearCookie(COOKIES.token)
    clearCookie(COOKIES.users)
    navigator('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loyaltyAuth]) // just check only loyaltyAuth, skip navigator
  return (
    <>
      <header className='layout hidden items-center justify-between py-6 lg:flex'>
        <div className='flex items-end justify-start gap-x-4'>
          <div className='flex items-center gap-x-2'>
            <Link to='/'>
              <Logo
                aria-label='Logo'
                className='h-5'
              />
            </Link>
          </div>
          {user && (
            <nav className='flex items-end gap-x-4 leading-3'>
              <Link
                to='/'
                className='text-gray-700 hover:text-blue-500'>
                Home
              </Link>
              <Link
                to='/about'
                className='text-gray-700 hover:text-blue-500'>
                Settings
              </Link>
              <Link
                to='/contact'
                className='text-gray-700 hover:text-blue-500'>
                Suport
              </Link>
            </nav>
          )}
        </div>
        {user !== undefined && <SignIn />}
        {user && (
          <>
            <button
              onClick={signOut}
              type='button'
              className='rounded-full bg-gray-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-800
                focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700'>
              Sign out
            </button>
          </>
        )}
      </header>
      <div className='hidden h-2 w-full bg-white lg:block'></div>
      <header className='layout mt-3 flex justify-between px-2.5 lg:hidden lg:px-0'>
        <div className='flex flex-col'>
          <span className='text-csm'>{greeting},</span>
          <span className='text-lg font-medium'>{user?.displayName}</span>
        </div>
        <div>
          {user?.photoURL || (
            <UserCircleSVG
              width={42}
              height={42}
            />
          )}
        </div>
      </header>
    </>
  )
}

export default Header
