import { Flex, Image } from '@chakra-ui/react'
import { TwCardContainer } from 'components/modules/twCard.module'
import { ICard } from 'types/card.module'
import { MerchantConfig } from 'types/program.module'

const MerchantCard: React.FC<{ merchantConfig: ICard; onClick: () => void }> = ({
  merchantConfig,
  onClick,
}) => {
  return (
    <TwCardContainer
      onClick={onClick}
      style={{ background: `${merchantConfig.primaryColor}` }}
      className='max-h-32 min-w-32 flex-[1_1_100%] border'>
      <div className='w-full'>
        <div className='h-18'>
          {merchantConfig.logo && (
            <Image
              src={merchantConfig.logo}
              objectFit={'contain'}
              height={'100%'}
            />
          )}
        </div>

        <span className='line-clamp-2 text-csm font-medium'>{merchantConfig.name}</span>
      </div>
    </TwCardContainer>
  )
}
export default MerchantCard
