import { User } from 'firebase/auth'
import { DataSnapshot } from 'firebase/database'
import { useCallback, useMemo, useState } from 'react'
import {
  ActiveLoyaltyCardEntry,
  CardConfig,
  MerchantEntry,
  MerchantPrivateStore,
  PendingLoyaltyCardEntry,
  PendingTeamMember,
  PendingTeamMemberResult,
  UserTeams,
} from '../functions/src/data/common'
import { FirebaseDb, useDatabaseNullableRefLiveValue } from './components/common/Firebase'
import { waitForDbMatch } from './util/WaitForDbMatch'

export async function waitForCard(
  firebaseDb: FirebaseDb,
  cardId: string,
  timeout = 30000,
): Promise<ActiveLoyaltyCardEntry | undefined> {
  return waitForDbMatch(
    firebaseDb.getRef(`/cards/${cardId}`),
    (snapshot: DataSnapshot) => snapshot.exists(),
    timeout,
  )
}

export type MerchantState =
  | {
      type: 'SUCCESS'
      merchantData: MerchantEntry
      updateCardConfig(cardConfigId: string, fn: (value: CardConfig) => CardConfig): Promise<void>
      addCard(user: User, email: string, cardConfigId: string): Promise<string | null>
    }
  | { type: 'FAIL' }
  | { type: 'MISSING' }
  | { type: 'PENDING' }

export function useUserTeamsStore(user: User | undefined | null, firebaseDb: FirebaseDb) {
  const ref = useMemo(() => {
    return user?.uid ? firebaseDb.getRef(`/usersPrivate/${user.uid}`) : undefined
  }, [user, firebaseDb])

  const userTeams = useDatabaseNullableRefLiveValue<UserTeams>({ ref })

  return useMemo(() => {
    if (userTeams instanceof Error) return { type: 'ERROR' } as const
    if (userTeams === undefined) return { type: 'PENDING' } as const
    else return { type: 'SUCCESS', teams: userTeams } as const
  }, [userTeams])
}

export function useMerchantStore(id: string | undefined, firebaseDb: FirebaseDb): MerchantState {
  const ref = useMemo(() => {
    return id ? firebaseDb.getRef(`/merchants/${id}`) : undefined
  }, [id, firebaseDb])

  const merchantData = useDatabaseNullableRefLiveValue<MerchantEntry>({ ref })
  return useMemo<MerchantState>(() => {
    if (!id) return { type: 'FAIL' }
    if (merchantData instanceof Error) return { type: 'FAIL' }
    if (merchantData === null) return { type: 'MISSING' }
    if (merchantData === undefined) return { type: 'PENDING' }

    return {
      type: 'SUCCESS',
      merchantData: merchantData,
      updateCardConfig: async (cardConfigId: string, fn: (value: CardConfig) => CardConfig) => {
        await firebaseDb
          .getRef(`/merchants/${id}/cardConfigs/${cardConfigId}`)
          .runTransaction((data: CardConfig) => fn(data))
      },
      addCard: async (user: User, email: string, cardConfigId: string) => {
        const newCardRef = await firebaseDb.getRef(`/pendingCards`).push({
          merchant: id,
          owner: user.uid,
          cardConfigId,
          email,
        } satisfies PendingLoyaltyCardEntry)
        const cardId = newCardRef.key
        if (!cardId) return null
        await waitForCard(firebaseDb, newCardRef.key)
        return newCardRef.key
      },
    }
  }, [merchantData, firebaseDb, id])
}

export type MerchantPermissionState =
  | {
      type: 'SUCCESS'
      permissions: 'owner'
      merchantData: MerchantPrivateStore
    }
  | { type: 'MISSING' }
  | { type: 'PENDING' }

export function useMerchantPrivateStore(
  id: string | undefined,
  user: User | null | undefined,
  firebaseDb: FirebaseDb,
) {
  const ref = useMemo(() => {
    return id && user?.uid ? firebaseDb.getRef(`/merchantsPrivate/${id}`) : undefined
  }, [id, firebaseDb, user?.uid])

  const teamResult = useDatabaseNullableRefLiveValue<MerchantPrivateStore>({ ref })

  return useMemo<MerchantPermissionState>(() => {
    if (!id) return { type: 'MISSING' }
    if (!user?.uid) return { type: 'MISSING' }
    if (teamResult === undefined) return { type: 'PENDING' }
    if (teamResult instanceof Error) return { type: 'MISSING' }
    if (teamResult === null) return { type: 'MISSING' }
    if (teamResult.members[user.uid]?.permission === 'owner')
      return { type: 'SUCCESS', permissions: 'owner', merchantData: teamResult }
    return { type: 'MISSING' }
  }, [id, user?.uid, teamResult])
}

async function waitForInviteResult(
  firebaseDb: FirebaseDb,
  key: string,
  timeout = 30000,
): Promise<PendingTeamMemberResult['result'] | undefined> {
  return waitForDbMatch(
    firebaseDb.getRef(`/pendingTeamMembers/${key}/result`),
    (snapshot: DataSnapshot) => snapshot.exists(),
    timeout,
  )
}

export function useMerchantInviteStore(firebaseDb: FirebaseDb) {
  const [joining, setJoining] = useState(false)
  const acceptInvite = useCallback(
    async (merchantId: string, userId: string, token: string) => {
      setJoining(true)
      const ref = await firebaseDb.getRef(`pendingTeamMembers`).push({
        token,
        userId,
        merchantId,
      } satisfies PendingTeamMember)

      let results: PendingTeamMemberResult['result'] | undefined = undefined
      if (ref.key) results = await waitForInviteResult(firebaseDb, ref.key)
      setJoining(false)
      return results === 'success'
    },

    [firebaseDb],
  )

  return useMemo(() => ({ acceptInvite, joining }), [acceptInvite, joining])
}
