import clsx from 'clsx'
import { MerchantQRCardV2 } from 'components/QRCode/MerchantQRCard'
import { FirebaseComponents } from 'components/common/Firebase'
import DropdownSelect from 'components/dropdown'
import { TwMainGridWrapper } from 'components/modules/twLayout.module'
import { User } from 'firebase/auth'
import { useState } from 'react'
import { IAddress } from 'types/program.module'
import NotificationBox from 'ui/NotificationBox'
import { MerchantEntry } from '../../../../functions/src/data/common'
import ProgramCard from './ProgramCard'

type DashboardMerchantProps = {
  merchantData: MerchantEntry
  locations: IAddress[]
  isTest: boolean
  user: User
  firebase: FirebaseComponents
}

const DashboardMerchant: React.FC<DashboardMerchantProps> = ({
  merchantData,
  locations,
  isTest,
  user,
  firebase,
}) => {
  const [addressSelected, setAddressSelected] = useState<string>('1')

  const AddressStores =
    (isTest && locations && Array(locations).length > 0 && locations.slice(0, 3)) || locations

  const AddressOption: any =
    AddressStores &&
    Array(AddressStores).length > 0 &&
    AddressStores.map((item: any) => ({
      value: item?.id,
      label: item?.address,
    }))

  return (
    <TwMainGridWrapper className='h-full'>
      <NotificationBox className='col-span-3' />
      <div className={clsx('grid grid-cols-1', { 'col-span-8': !isTest, 'col-span-5': isTest })}>
        <ProgramCard
          merchant={merchantData as MerchantEntry}
          uid={(user?.uid as string) || ''}
        />
      </div>
      {isTest && (
        <div className='col-span-12 bg-white p-4 shadow-md sm:col-span-4'>
          <div>
            <h2 className='mb-4 text-xl font-semibold'>Test your program</h2>
            <p>Use the QR code below to test how it all works.</p>
            <p>
              Once you launch your program, we&apos;ll send you a physical QR Code so customers can
              scan every time they make a purchase.
            </p>
          </div>
          {user?.uid && (
            <div className='mt-4 flex flex-col gap-y-4'>
              <DropdownSelect
                options={AddressOption}
                label='Address'
                value={addressSelected}
                onChange={(value) => setAddressSelected(value)}
              />
              <div className='flex justify-center'>
                <MerchantQRCardV2
                  configId={'tokens'}
                  merchantId={user?.uid}
                  firebase={firebase}
                  user={user}
                  qrUrl={`${window.location.origin}/merchant/${user?.uid}?address={${addressSelected}}&instorescan=true`}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </TwMainGridWrapper>
  )
}
export default DashboardMerchant
