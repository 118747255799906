import { BrandedQRCard } from 'BrandedQRCardV2'
import { useMerchantStore } from 'UseMerchantStore'
import { FirebaseComponents } from 'components/common/Firebase'
import { User } from 'firebase/auth'

export function MerchantQRCardV2(props: {
  merchantId: string
  firebase: FirebaseComponents
  qrUrl: string
  onFlipClicked?: () => void
  configId: string | undefined
  user: User | undefined | null
}) {
  const merchantState = useMerchantStore(props.merchantId, props.firebase.firebaseDb)
  // const { notificationRegisterer, pwaInstaller } = useContext(pwaContext)

  // useEffect(() => {
  //   if (
  //     notificationRegisterer &&
  //     notificationRegisterer.notificationRegistrationState !== 'Not supported' &&
  //     notificationRegisterer.notificationRegistrationState !== 'Ready'
  //   ) {
  //     // notificationRegisterer.requestForNotifications()
  //   }
  // }, [notificationRegisterer])
  return (
    <>
      {merchantState.type === 'SUCCESS' && (
        <>
          {/* {notificationRegisterer?.notificationRegistrationState === 'Ready' &&
            `You will receive Loyalty Notifications for this store. 👍`}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            !pwaInstaller?.appInstallState && (
              <RoundButton onClick={pwaInstaller?.installPwa}>
                Install App to get notifications
              </RoundButton>
            )}
          {pwaInstaller?.installPwa &&
            pwaInstaller?.appDisplayMode === 'browser_tab' &&
            pwaInstaller?.appInstallState === 'Installing' && (
              <RoundButton aria-disabled={true}>Installing...</RoundButton>
            )} */}
          <BrandedQRCard
            qrUrl={props.qrUrl}
            logo={merchantState.merchantData.logo}
            fontFamily={'LeagueSpartan'}
            cardTitle={
              merchantState.merchantData.cardConfigs[
                props.configId ?? merchantState.merchantData.defaultCardConfigId
              ].title ?? undefined
            }
            theme={{ backgroundColor: 'black' }}
            onFlipClicked={props.onFlipClicked}
          />
        </>
      )}
    </>
  )
}
