import {
  Component,
  DependencyList,
  EffectCallback,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

export function usePromiseState<T>(
  init: () => Promise<T> | undefined,
  deps: DependencyList,
): T | undefined {
  const [state, setState] = useState<T>()
  // eslint-disable-next-line
  const promise: Promise<T> | undefined = useMemo(init, deps)
  useEffect(() => {
    if (!promise) {
      setState(undefined)
      return
    }
    promise.then(setState)
  }, [promise])
  return state
}

/**
 * A hook that runs a callback when the dependencies change similar to useEffect but only runs after the first render
 */
export function useDidUpdateEffect(fn: EffectCallback, inputs: DependencyList) {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      return fn()
    }
    didMountRef.current = true
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, inputs)
}

type Destructor = (() => void) | void

export function useOnChangeEffect<T extends object>(
  fn: (newDeps: T, old: T | undefined) => void | Destructor,
  inputs: T,
) {
  const [oldStateRef, setOldState] = useMutableRef<T>()
  // eslint-disable-next-line
  const callback = useCallback(fn, [inputs])
  useEffect(
    () => {
      const oldState = oldStateRef.current
      callback(inputs, oldState)
      setOldState(inputs)
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [inputs],
  )
}

export function isInView(
  node: HTMLElement | null,
  scrollContainer: HTMLElement | undefined | null = node?.parentNode as
    | HTMLElement
    | undefined
    | null,
) {
  if (!node || !scrollContainer) return false

  const parent = scrollContainer
  const rect = node.getBoundingClientRect()
  const parentRect = parent.getBoundingClientRect()

  return (
    rect.top >= parentRect.top &&
    rect.left >= parentRect.left &&
    rect.bottom <= parentRect.bottom &&
    rect.right <= parentRect.right
  )
}

export const scrollIntoViewIfNeeded = (
  node: HTMLElement | null,
  scrollContainer: HTMLElement | undefined | null = node?.parentNode as
    | HTMLElement
    | undefined
    | null,
  centerIfNeeded = true,
) => {
  if (!node || !scrollContainer) return

  const parent = scrollContainer
  const rect = node.getBoundingClientRect()
  const parentRect = parent.getBoundingClientRect()

  const inView = isInView(node, parent)

  if (!inView) {
    if (centerIfNeeded) {
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2
      const x = centerX - parentRect.width / 2
      const y = centerY - parentRect.height / 2

      if (parent.scrollTo) {
        parent.scrollTo({
          left: parent.scrollLeft + x,
          top: parent.scrollTop + y,
          behavior: 'smooth',
        })
      }
    } else {
      node.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export function useElementBoundingRectSize<T extends HTMLDivElement>(): [
  (node: T | undefined | null) => void,
  { width: number; height: number },
] {
  const [rect, setRect] = useState<{ width: number; height: number }>()
  const [box, boxRef] = useState<T | undefined | null>()
  useEffect(() => {
    if (!box) return
    const observer = new ResizeObserver(() => {
      setRect(box?.getBoundingClientRect())
    })
    observer.observe(box)
    return () => {
      observer.disconnect()
    }
  }, [box])

  return [boxRef, rect ?? { width: 0, height: 0 }]
}

export class ErrorBoundary extends Component<PropsWithChildren> {
  state = { hasError: false }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export function disableErrorOverlay() {
  const disableErrorsStyle = document.createElement('style')
  disableErrorsStyle.innerText = `body>iframe{display:none;}`
  document.head.appendChild(disableErrorsStyle)
}

export function useMutableRef<T extends object | string | boolean | undefined>(
  initialState?: T,
): [MutableRefObject<T | undefined>, React.Dispatch<React.SetStateAction<T | undefined>>] {
  const ref = useRef<T | undefined>(initialState)
  const setClips = useCallback((v: React.SetStateAction<T | undefined>) => {
    ref.current = typeof v === 'function' ? v(ref.current) : v
  }, [])

  return useMemo(() => [ref, setClips], [setClips])
}
