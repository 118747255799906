import { COOKIES } from 'constants/common'
import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { getCookie } from 'util/cookies'
import { decodeString } from 'util/hash'

const useAuth = () => {
  const [userInfo, setUserInfo] = useState<User | null>(null)

  useEffect(() => {
    const getUser = getCookie(COOKIES.users)
    if (getUser) {
      try {
        const userDecode = decodeString(getUser)
        const user = JSON.parse(userDecode) as User
        setUserInfo(user)
      } catch (error) {
        console.error('Error decoding or parsing user info:', error)
        setUserInfo(null)
      }
    }
  }, [])

  return {
    userInfo,
  }
}
export default useAuth
