import { Box, BoxProps } from '@chakra-ui/react'
import React, { ReactNode, RefObject } from 'react'

export interface SquashingBoxProps extends BoxProps {
  childrenArray?: ReactNode[]
}

export class SquashingBox extends React.Component<SquashingBoxProps> {
  private ref: RefObject<HTMLDivElement>
  private map: Map<number, number>
  private observer: ResizeObserver | null = null
  private index = 0

  constructor(props: SquashingBoxProps) {
    super(props)

    this.ref = React.createRef()
    this.map = new Map<number, number>()
  }

  blur = () => {
    return this.ref.current?.blur()
  }
  focus = () => {
    return this.ref.current?.focus()
  }
  availableChildren = () => [this.props.children].concat(this.props.childrenArray ?? [])

  componentDidMount() {
    const box = this.ref.current
    if (!box) return
    this.observer = new ResizeObserver(() => {
      if (box.scrollWidth && box.clientWidth < box.scrollWidth) {
        if (this.availableChildren().length > this.index + 1) {
          this.map.set(this.index, box.scrollWidth)
          this.index += 1
          this.forceUpdate()
        }
      } else if (
        box.scrollWidth &&
        box.clientWidth > box.scrollWidth &&
        this.map.has(this.index - 1) &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        box.clientWidth > this.map.get(this.index - 1)!
      ) {
        this.index -= 1
        this.forceUpdate()
      }
    })

    this.observer.observe(box)
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  render() {
    const { childrenArray, ...boxProps } = this.props

    return (
      <Box
        {...boxProps}
        style={{
          ...boxProps.style,
          overflowX:
            boxProps.style?.overflowX ? boxProps.style?.overflowX
            : this.props.childrenArray?.length ? 'clip'
            : undefined,
        }}
        ref={this.ref}>
        {this.availableChildren()[this.index]}
      </Box>
    )
  }
}
