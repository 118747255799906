import { useUserTeamsStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import { DraggableScrollBox } from 'components/DraggableScrollBox'
import Card from 'components/card/Card'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { CARD_CONFIG } from 'constants/common'
import useAuth from 'hooks/useAuth'
import { useMerchantData } from 'hooks/useMerchantData'
import LiveMerchantCard from 'pages/Merchant/components/LiveMerchantCard'
import React, { useCallback, useState } from 'react'
import { firebaseConfig } from 'services/FirebaseConfig'
import { ICard } from 'types/card.module'
import { MerchantEntry, TokenCardConfig } from '../../../../functions/src/data/common'
import DashboardMerchant from './DashboardMerchant'

const Dashboard: React.FC = () => {
  const { userInfo } = useAuth()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { user } = useUserNullable({ auth: firebase.auth })

  const [showMerchantCard, setShowMerchantCad] = useState<ICard | null>()

  const merchantData = useMerchantData(userInfo?.uid || '')
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const state = useUserCardsStore(user, firebaseDb)

  const { cardConfigs } = (merchantData && (merchantData.merchant as MerchantEntry)) || {}
  const { locations } = (cardConfigs && (cardConfigs['tokens'] as TokenCardConfig)) || {}

  const isTest =
    (cardConfigs && (cardConfigs[CARD_CONFIG.tokens] as TokenCardConfig)?.isTest) || false

  const handleShowCard = useCallback(
    (e: ICard) => {
      if (e.name === showMerchantCard?.name) {
        setShowMerchantCad(null)
        return
      }
      setShowMerchantCad(e)
    },
    [showMerchantCard],
  )

  if (merchantData === undefined) {
    return <SkeletonHome />
  }

  if (userTeams.teams && user) {
    return (
      <section className='layout bg-light-mb-main'>
        <DashboardMerchant
          firebase={firebase}
          user={user}
          isTest={isTest}
          locations={locations || []}
          merchantData={merchantData?.merchant as MerchantEntry}
        />
      </section>
    )
  }

  return (
    <section className='layout bg-light-mb-main'>
      {user?.uid && (
        <>
          <div className='mt-8 space-y-8 px-2.5'>
            <div>
              <h4 className='text-xl font-semibold capitalize'>My programs</h4>
            </div>
            {state.type === 'SUCCESS' && !!Object.entries(state.cardsData).length && (
              <DraggableScrollBox direction={'horizontal'}>
                <div className='no-scrollbar flex w-full gap-3'>
                  {Object.entries(state.cardsData).map(([merchantId]) => {
                    return (
                      <LiveMerchantCard
                        onClick={handleShowCard}
                        key={merchantId}
                        merchantId={merchantId}
                        firebaseDb={firebaseDb}
                      />
                    )
                  })}
                </div>
              </DraggableScrollBox>
            )}
            <div>
              {showMerchantCard && (
                <Card
                  {...showMerchantCard}
                  isHaveData={!!showMerchantCard}
                />
              )}
            </div>
          </div>
        </>
      )}

      {state.type === 'NO_CARD' && (
        <div className='mt-4 text-center'>
          Loading Loyalty programs in your area...
          <br />
          <br />
          Scan a Loyalty QR to get started
        </div>
      )}
    </section>
  )
}

export default Dashboard
