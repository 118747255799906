import { Box, Flex, Image, NumberInput, NumberInputField } from '@chakra-ui/react'
import binIcon from './icons/bin_white_icon.png'
import plusIcon from './icons/plus_button.png'
import React, { CSSProperties, ReactNode, useMemo, useState } from 'react'
import { useElementBoundingRectSize } from './util/reactutils'
import { RoundButton } from './components/common/RoundButton'
import { BrandedQRCard } from './BrandedQRCard'
import {
  ActiveLoyaltyCardEntry,
  ActivePointBank,
  ActivePointLoyaltyCardEntry,
  ActiveScratchyBank,
  ActiveTokenBank,
  MerchantEntry,
  PointBalance,
  PointOfferEntry,
  TokenOfferEntry,
} from '../functions/src/data/common'
import './components/common/utils/typescriptUtils'
import { CardContainer } from './components/CardContainer'
import { showDialog } from './components/common/Dialog'
import { PillContainer } from './util/dialogUtils'
import { DraggableScrollBox } from './components/DraggableScrollBox'
import { toast } from 'react-toastify'

export type PointLoyaltyCardProps = {
  type: 'points'
  card: ActivePointLoyaltyCardEntry
  onManualEntrySubmitted?: (pointType: string, operation: 'give' | 'take', amount: number) => void
  onClaimClickConfirmed?: (offerLabel: string, pointType: string, cost: number) => void
}
export type TokenLoyaltyCardProps = {
  type: 'tokens'
  bank: ActiveTokenBank
  onDecrementClicked?: (tokenType: string) => void
  onIncrementClicked?: (tokenType: string) => void
  onClaimClicked?: (offerLabel: string, tokenType: string, cost: number) => void
}
export type ScratchyLoyaltyCardProps = {
  type: 'scratchy'
  bank: ActiveScratchyBank
}

export function LoyaltyCard(
  props: {
    qrUrl: string
    logo: string | undefined
    fontFamily: string
    cardTitle: string | undefined
    markedIcon: string
    theme: { backgroundColor: string }
    editable: boolean
    cardConfigId: string | undefined
    merchantEntry: MerchantEntry | undefined
  } & (PointLoyaltyCardProps | TokenLoyaltyCardProps | ScratchyLoyaltyCardProps),
) {
  const [showQR, setShowQR] = useState(false)
  const cardConfig = useMemo(
    () =>
      props.merchantEntry &&
      props.merchantEntry.cardConfigs[
        props.cardConfigId ?? props.merchantEntry.defaultCardConfigId
      ],
    [props.cardConfigId, props.merchantEntry],
  )
  return (
    <>
      {showQR && (
        <BrandedQRCard
          qrUrl={props.qrUrl}
          logo={props.logo}
          fontFamily={props.fontFamily}
          cardTitle={props.cardTitle}
          theme={props.theme}
          onFlipClicked={() => setShowQR((it) => !it)}
        />
      )}
      {!showQR && (
        <CardContainer
          fontFamily={props.fontFamily}
          style={{
            boxShadow: '0 0 20px 20px rgb(255,255,255,0.5), 0 0 20px 10px rgb(0,0,0,0.3)',
            backgroundColor: props.theme.backgroundColor,
          }}>
          <Flex
            justifyContent={'space-between'}
            flexShrink={1}
            flexGrow={1}
            flexBasis={'auto'}
            height={'20%'}>
            {props.logo && (
              <Image
                src={props.logo}
                objectFit={'contain'}
                height={'100%'}
              />
            )}
            <RoundButton
              alt={'barcode'}
              onClick={() => setShowQR((it) => !it)}>
              Show Code
            </RoundButton>
          </Flex>
          <Flex
            flexGrow={1}
            flexShrink={1}
            flexBasis={'auto'}
            position={'relative'}>
            <span style={{ fontSize: '100%' }}>{props.cardTitle}</span>
          </Flex>
          <Flex
            flexGrow={1}
            flexBasis={'70%'}>
            {props.type === 'tokens' && (
              <TokenLoyaltyBank
                tokenTypes={Object.entries(props.bank.balance).map(([tokenType, status]) => ({
                  lineMax: status.lineMax ?? status.target,
                  max: status.target,
                  marked: status.marked ?? 0,
                  offers: status.offers,
                  tokenType: tokenType,
                }))}
                editable={props.editable}
                onIncrementClicked={props.editable ? props.onIncrementClicked : undefined}
                onDecrementClicked={props.editable ? props.onDecrementClicked : undefined}
                onClaimClicked={props.editable ? props.onClaimClicked : undefined}
                markedIcon={props.markedIcon}
              />
            )}
            {props.type === 'points' && (
              <PointsLoyaltyBank
                balanceTypes={Object.entries(props.card.balance).map(([pointType, status]) => ({
                  type: pointType,
                  label: status.label,
                  points: status.points ?? 0,
                  offers:
                    cardConfig?.cardConfigType === 'points' ?
                      Object.values(cardConfig?.templates[pointType]?.offers ?? [])
                    : [],
                }))}
                editable={props.editable}
                onClaimClicked={
                  props.editable ?
                    props.onClaimClickConfirmed &&
                    ((offerLabel: string, pointType: string, cost: number) => {
                      if (cost > (props.card.balance[pointType].points ?? 0)) {
                        toast('Not enough points to claim', {
                          position: 'top-center',
                          autoClose: 1000,
                          type: 'warning',
                        })
                        return
                      }

                      showDialog({
                        title: 'Confirm Claim',
                        children: (FocusSpan) => (
                          <Flex
                            direction={'column'}
                            gap={10}>
                            <span>
                              Are you sure you want to claim{' '}
                              <FocusSpan>
                                {offerLabel} for {cost} points
                              </FocusSpan>
                              ?
                              <br />
                              <br />
                              {props.card.email} will have{' '}
                              {(props.card.balance[pointType].points ?? 0) - cost} points left.
                            </span>
                          </Flex>
                        ),
                        positiveButtonProps: {
                          text: 'Confirm',
                          onClicked: () => {
                            props.onClaimClickConfirmed?.(offerLabel, pointType, cost)
                            return true
                          },
                        },
                        negativeButtonProps: 'Cancel',
                      })
                    })
                  : undefined
                }
                onManualEntryClicked={(pointsType) =>
                  showManualPointsEntryDialog(props.card, pointsType, (state) => {
                    showDialog({
                      title: 'Confirm Points',
                      children: (
                        FocusSpan,
                        _,
                        _setState,
                        _triggerPositiveButton,
                        _focusPositiveButton,
                      ) => (
                        <Flex
                          direction={'column'}
                          gap={10}>
                          <span>
                            Are you sure you want to{' '}
                            <FocusSpan>
                              {state.operation} {state.value} points
                            </FocusSpan>
                            ?
                            <br />
                            <br />
                            {props.card.email} will have{' '}
                            {state.operation === 'give' ?
                              (props.card.balance[pointsType].points ?? 0) + state.value
                            : (props.card.balance[pointsType].points ?? 0) - state.value}{' '}
                            points left.
                          </span>
                        </Flex>
                      ),
                      positiveButtonProps: {
                        text: 'Confirm',
                        onClicked: () => {
                          props.onManualEntrySubmitted?.(pointsType, state.operation, state.value)
                          return true
                        },
                      },
                      negativeButtonProps: 'Cancel',
                    })
                  })
                }
                markedIcon={props.markedIcon}
              />
            )}
          </Flex>
        </CardContainer>
      )}
    </>
  )
}

export function PointsLoyaltyBank(props: {
  markedIcon: string
  balanceTypes: {
    type: string
    label: string
    points: number
    offers: PointOfferEntry[]
  }[]
  editable: boolean
  onClaimClicked?: (offerLabel: string, pointType: string, cost: number) => void
  onManualEntryClicked?: (pointType: string) => void
}) {
  const [ref, size] = useElementBoundingRectSize()
  return (
    <Flex
      ref={ref}
      direction={'column'}
      gap={10}
      rowGap={30}
      width={'100%'}>
      {props.balanceTypes.map(({ offers, points, type, label }, _index, _array) => (
        <PointLoyaltyBalance
          key={type}
          points={points}
          type={type}
          label={label}
          offers={offers}
          onManualEntryClicked={props.onManualEntryClicked}
          onClaimClicked={props.onClaimClicked}
          editable={props.editable}
        />
      ))}
    </Flex>
  )
}

export const PointLoyaltyBalance = ({
  type,
  points,
  offers,
  label,
  ...props
}: {
  type: string
  label: string
  points: number
  offers: PointOfferEntry[]
  editable: boolean
  onManualEntryClicked?: (type: string) => void
  onClaimClicked?: (offerLabel: string, type: string, cost: number) => void
}) => (
  <Flex
    key={type}
    direction={'row'}
    alignItems={'center'}
    gap={10}
    rowGap={0}
    wrap={'wrap'}>
    <h2 style={{ marginBottom: 0, marginTop: 0, fontFamily: 'Montserrat' }}>{label}</h2>
    <>{(points ?? 0).toLocaleString()}pts</>
    {!!offers.length && (
      <>
        <Box flexBasis={'100%'} />
        <h4 style={{ marginBottom: 0, marginTop: 0 }}>Menu</h4>

        <Box flexBasis={'100%'} />
        <DraggableScrollBox direction={'horizontal'}>
          <Flex
            direction={'row'}
            padding={10}
            gap={10}>
            {offers.map((offer, index) => {
              return (
                <>
                  {offer.offerType === 'manual-entry' && props.editable && (
                    <LoyaltyMenuItem
                      key={`manual_${index}`}
                      getChildren={() => [<>Manual Entry</>]}
                      onClick={() => props.onManualEntryClicked?.(type)}
                    />
                  )}
                  {offer.offerType === 'custom' && (
                    <LoyaltyMenuItem
                      key={`custom_${index}`}
                      onClick={() => props.onClaimClicked?.(offer.label, type, offer.cost)}
                      getChildren={(_width, _height) => [
                        <Box
                          key={1}
                          boxSizing={'border-box'}
                          textAlign={'center'}>
                          {offer.label}
                          <br />
                          {offer.cost.toLocaleString()}pts
                        </Box>,
                      ]}
                    />
                  )}
                </>
              )
            })}
          </Flex>
        </DraggableScrollBox>
      </>
    )}
    <Box flexBasis={'100%'} />
  </Flex>
)

export function TokenLoyaltyBank(props: {
  markedIcon: string
  tokenTypes: {
    marked: number
    max: number
    offers: TokenOfferEntry[]
    tokenType: string
    lineMax: number
  }[]
  editable: boolean
  onDecrementClicked?: (tokenType: string) => void
  onIncrementClicked?: (tokenType: string) => void
  onClaimClicked?: (offerLabel: string, tokenType: string, cost: number) => void
}) {
  const [ref, size] = useElementBoundingRectSize()
  return (
    <Flex
      ref={ref}
      gap={10}
      rowGap={0}
      wrap={'wrap'}
      width={'100%'}>
      {props.tokenTypes.map(({ marked, max, offers, lineMax, tokenType }, _index, _array) => {
        const realMax = max + offers.length
        const sortedOffers = offers.orderBy((it) => it.cost)
        const tokenRange = [...Array(max).keys()]
        const displayCells: (
          | { type: 'TOKEN'; tokenIndex: number }
          | { type: 'OFFER'; offer: TokenOfferEntry }
        )[] = sortedOffers.flatMap((offer, index, offers) => {
          if (index === 0) {
            return [
              ...tokenRange.slice(0, offer.cost).map(
                (index) =>
                  ({
                    type: 'TOKEN',
                    tokenIndex: index,
                  }) satisfies { type: 'TOKEN'; tokenIndex: number },
              ),
              { type: 'OFFER', offer },
            ]
          }
          return [
            ...tokenRange.slice(offers[index - 1].cost + index - 1, offer.cost + index - 1).map(
              (index) =>
                ({
                  type: 'TOKEN',
                  tokenIndex: index,
                }) satisfies { type: 'TOKEN'; tokenIndex: number },
            ),
            { type: 'OFFER', offer },
          ]
        })
        return (
          <>
            {displayCells.map((cell, index) => (
              <>
                {cell.type === 'TOKEN' && (
                  <LoyaltyCell
                    key={index}
                    style={{ minWidth: 30 }}
                    getChildren={(width, height) => [
                      marked > index && (
                        <Image
                          w={width}
                          h={height}
                          boxSizing={'border-box'}
                          src={props.markedIcon}
                        />
                      ),
                      marked > index && props.editable && (
                        <Image
                          p={'20%'}
                          boxSizing={'border-box'}
                          w={width}
                          h={height}
                          src={binIcon}
                        />
                      ),
                      marked <= index && props.editable && (
                        <Image
                          w={width}
                          p={'20%'}
                          boxSizing={'border-box'}
                          h={height}
                          src={plusIcon}
                        />
                      ),
                    ]}
                    onClick={() =>
                      marked > index ?
                        props.onDecrementClicked?.(tokenType)
                      : props.onIncrementClicked?.(tokenType)
                    }
                  />
                )}
                {cell.type === 'OFFER' && (
                  <LoyaltyCell
                    key={index}
                    getChildren={(_width, _height) => [<>{cell.offer.label}</>]}
                    getHoverChildren={() => [
                      <span
                        key={1}
                        style={{ color: 'black' }}>
                        Claim
                      </span>,
                    ]}
                    disabled={cell.offer.cost > marked}
                    onClick={
                      props.onClaimClicked ?
                        () => props.onClaimClicked?.(cell.offer.label, tokenType, cell.offer.cost)
                      : undefined
                    }
                  />
                )}
                {index + 1 === lineMax + 1 && <Box flexBasis={'100%'} />}
              </>
            ))}
            <Box flexBasis={'100%'} />
          </>
        )
      })}
    </Flex>
  )
}

export function LoyaltyMenuItem(props: {
  getChildren?: (width: number, height: number) => ReactNode[]
  getHoverChildren?: (width: number, height: number) => ReactNode[]
  invisible?: boolean
  disabled?: boolean
  onClick?: () => void
  style?: CSSProperties
}) {
  const [ref, size] = useElementBoundingRectSize()

  return (
    <Flex
      ref={ref}
      style={{ height: 100, width: 150, ...props.style }}
      alignItems={'center'}
      justifyContent={'center'}
      flexBasis={'auto'}
      role={'group'}
      borderRadius={10}
      backgroundColor={'white'}
      position={'relative'}
      color={'black'}
      px={10}
      border={!props.invisible ? '1px solid white' : undefined}
      _hover={{
        backgroundColor: !props.invisible && !props.disabled ? 'rgb(255,255,255,0.5)' : undefined,
      }}
      onClick={!props.disabled ? props.onClick : undefined}>
      {props.getChildren?.(size.width, size.height).map((it, index) => (
        <Flex
          key={`child_${index}`}
          position={'absolute'}
          alignItems={'center'}
          justifyContent={'center'}
          width={size.width}
          height={size.height}>
          {it}
        </Flex>
      ))}
      {!props.disabled &&
        props.getHoverChildren?.(size.width, size.height).map((it, index) => (
          <Flex
            key={`hover_${index}`}
            _groupHover={{ display: 'flex' }}
            display={'none'}
            position={'absolute'}
            alignItems={'center'}
            justifyContent={'center'}
            width={size.width}
            height={size.height}>
            {it}
          </Flex>
        ))}
    </Flex>
  )
}

export function LoyaltyCell(props: {
  getChildren?: (width: number, height: number) => ReactNode[]
  getHoverChildren?: (width: number, height: number) => ReactNode[]
  invisible?: boolean
  disabled?: boolean
  onClick?: () => void
  style?: CSSProperties
}) {
  const [ref, size] = useElementBoundingRectSize()

  return (
    <Flex
      ref={ref}
      style={{ maxHeight: size.width, ...props.style }}
      alignItems={'center'}
      justifyContent={'center'}
      flexBasis={'auto'}
      flexGrow={1}
      role={'group'}
      position={'relative'}
      aspectRatio={1}
      flexShrink={1}
      borderRadius={99999}
      border={!props.invisible ? '1px solid white' : undefined}
      _hover={{
        backgroundColor: !props.invisible && !props.disabled ? 'rgb(255,255,255,0.5)' : undefined,
      }}
      onClick={!props.disabled ? props.onClick : undefined}>
      {props.getChildren?.(size.width, size.height).map((it, index) => (
        <Flex
          key={`child_${index}`}
          position={'absolute'}
          alignItems={'center'}
          justifyContent={'center'}
          width={size.width}
          height={size.height}>
          {it}
        </Flex>
      ))}
      {!props.disabled &&
        props.getHoverChildren?.(size.width, size.height).map((it, index) => (
          <Flex
            key={`hover_${index}`}
            _groupHover={{ display: 'flex' }}
            display={'none'}
            position={'absolute'}
            alignItems={'center'}
            justifyContent={'center'}
            width={size.width}
            height={size.height}>
            {it}
          </Flex>
        ))}
    </Flex>
  )
}

type ManualPointEntryState = { operation?: 'give' | 'take'; value?: number }

function showManualPointsEntryDialog(
  card: ActivePointLoyaltyCardEntry,
  pointType: string,
  onSubmit: (state: Required<ManualPointEntryState>) => void,
) {
  const balance = card.balance[pointType]
  showDialog<ManualPointEntryState>(
    {
      title: 'Manual Points Entry',
      positiveButtonProps: {
        text: 'Submit',
        disabled: (state) =>
          !state?.value ||
          !state?.operation ||
          (balance.points !== undefined &&
            state.operation === 'take' &&
            state.value > balance.points),
        onClicked: (state) => {
          if (!!state?.operation && !!state.value) {
            onSubmit({ operation: state.operation, value: state.value })
            return true
          }
          return false
        },
      },
      negativeButtonProps: 'Cancel',
      children: (_FocusSpan, state, setState, _triggerPositiveButton, _focusPositiveButton) => (
        <Flex
          gap={8}
          direction={'column'}>
          <PillContainer
            pills={[
              {
                title: 'Give',
                onClick: () => {
                  setState((it) => ({ ...it, operation: 'give' }))
                },
                selected: state?.operation === 'give',
              },
              {
                title: 'Take',
                onClick: () => {
                  setState((it) => ({ ...it, operation: 'take' }))
                },
                selected: state?.operation === 'take',
              },
            ]}
          />
          <Box>
            {state &&
              !!state.value &&
              state.operation === 'take' &&
              state.value > (balance.points ?? 0) && (
                <>
                  {card.email ? card.email : card.owner} has {balance.points} points. You can&apos;t
                  take more than that.
                </>
              )}
          </Box>
          <NumberInput>
            <NumberInputField
              autoFocus={true}
              className={'editable-div'}
              placeholder={'Enter amount of points'}
              onChange={(event) =>
                setState((it) => ({
                  ...it,
                  value: event.target.value ? parseInt(event.target.value) : 0,
                }))
              }
            />
          </NumberInput>
        </Flex>
      ),
    },
    { operation: 'give' },
  )
}
