import clsx from 'clsx'
import { PATH } from 'constants/routes'
import { ReactComponent as HistorySVG } from 'icons/history.svg'
import { ReactComponent as HomeSVG } from 'icons/home.svg'
import { ReactComponent as NotificationSVG } from 'icons/notification.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

export const MbStickyFooter: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  console.log('🚀 ~ location.pathname === PATH.HOME:', location.pathname === PATH.HOME)

  return (
    <aside className='shadow-top sticky bottom-0 z-20 divide-y bg-light-mb-main lg:hidden'>
      <div className='flex text-cxs md:text-xs'>
        <TwBtmMenuItem
          onClick={() => navigate(PATH.HOME)}
          className={clsx({ '!text-black': location.pathname === PATH.HOME })}>
          <HomeSVG className='h-5 w-5' />
          <TwBtmMenuText>Home</TwBtmMenuText>
        </TwBtmMenuItem>
        <TwBtmMenuItem>
          <HistorySVG className='h-5 w-5' />
          <TwBtmMenuText>History</TwBtmMenuText>
        </TwBtmMenuItem>
        <TwBtmMenuItem>
          <NotificationSVG className='h-5 w-5' />
          <TwBtmMenuText>Notification</TwBtmMenuText>
        </TwBtmMenuItem>
        <TwBtmMenuItem>
          <QRCodeSVG className='h-5 w-5' />
          <TwBtmMenuText>QR Code</TwBtmMenuText>
        </TwBtmMenuItem>
      </div>
    </aside>
  )
}
export default MbStickyFooter
export const TwBtmMenuItem = tw.div`py-1.5 gap-1 text-light-disabled md:py-3 flex cursor-pointer drop-shadow-2xl flex-1 flex-col place-content-center items-center`
export const TwBtmMenuText = tw.div`whitespace-nowrap text-xs font-medium`
