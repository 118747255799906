import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import Card from 'components/card/Card'
import { FirebaseDb } from 'components/common/Firebase'

interface ILoyaltyCardSheetV2 {
  cardId: string
  firebaseDb: FirebaseDb
}
export const LoyaltyCardSheetV2: React.FC<ILoyaltyCardSheetV2> = ({ cardId, firebaseDb }) => {
  const state = useLoyaltyCardStore(cardId, firebaseDb)
  const dataCard: any = state.type === 'SUCCESS' && state.cardData
  const configCard = dataCard?.balance?.['default']
  const pointsCard =
    configCard &&
    Array(configCard?.lineMax).length > 0 &&
    Array.from({ length: configCard?.lineMax }, (_, i) => i + 1)
  return (
    <>
      {state.type === 'PENDING' && <div className='text-center'>Loading....</div>}
      {state.type === 'SUCCESS' && (
        <Card
          primaryColor={configCard?.primaryColor}
          secondaryColor={configCard?.secondaryColor}
          name={dataCard?.title}
          logo={dataCard?.logo}
          isCreate={false}
          isHaveData
          points={pointsCard}
        />
      )}
    </>
  )
}
