import { Button } from '@chakra-ui/react'
import { ButtonIcon } from 'components/button/ButtonIcon'
import Card from 'components/card/Card'
import { CARD_CONFIG } from 'constants/common'
import { PATH } from 'constants/routes'
import { ReactComponent as ShoppingCardSVG } from 'icons/shopping-cart.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProgramStore } from 'stores/programStore'
import { MerchantEntry, TokenCardConfig } from '../../../../functions/src/data/common'

interface IProgramCard {
  merchant?: MerchantEntry
  isCreate?: boolean
  isEdit?: boolean
  uid?: string
}

const ProgramCard: React.FC<IProgramCard> = ({
  isCreate = false,
  merchant,
  isEdit = false,
  uid,
}) => {
  const [isHaveData, setIsHaveData] = useState<boolean>(false)
  const navigate = useNavigate()
  const { programBody } = useProgramStore()
  const {
    name: nameBody,
    primaryColor: primaryColorBody,
    secondaryColor: secondaryColorBody,
    logo: logoBody,
    reward,
  } = programBody

  const onClickToCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    navigate(PATH.PROGRAM_CREATE)
  }

  useEffect(() => {
    if (!isHaveData && merchant) {
      setIsHaveData(true)
    }
  }, [isHaveData, merchant])

  const { logo, name, cardConfigs } = merchant || {}
  const { isTest, templates } =
    (cardConfigs && (cardConfigs[CARD_CONFIG.tokens] as TokenCardConfig)) || {}

  const {
    lineMax,
    primaryColor,
    secondaryColor,
    trial,
    target: count,
  } = (templates && templates['default']) || {}
  const points =
    ((reward || lineMax) &&
      Array.from({ length: reward || (lineMax as number) }, (_, i) => i + 1)) ||
    []

  return (
    <div className='col-span-6 rounded bg-white p-4 pb-6 shadow-md lg:col-span-4'>
      <div className='flex items-start justify-between'>
        <h2 className='mb-4 text-xl font-semibold'>Program</h2>
        <>
          {!isEdit && !isCreate && isTest && uid && (
            <>
              <div className='flex items-center gap-x-2 rounded-xl bg-yellow-400 px-1'>
                <ShoppingCardSVG className='h-5 w-5' />
                <p>Inactive</p>
              </div>
              <Button onClick={() => navigate(PATH.PROGRAM_EDIT.replace(':id', uid))}>Edit</Button>
            </>
          )}
        </>
      </div>
      <Card
        primaryColor={(primaryColorBody || primaryColor) as string}
        secondaryColor={(secondaryColorBody || secondaryColor) as string}
        name={(name || nameBody) as string}
        logo={(logo || logoBody) as string}
        isCreate={isCreate}
        onClick={onClickToCreate}
        points={points}
        isHaveData={isHaveData}
      />
      {!isEdit && !isCreate && isTest && (
        <div className='mt-8 space-y-4 md:col-span-2'>
          <p className='text-light-secondary'>Your program is not active.</p>
          <p>Launch your program today to start rewarding your customers</p>
          <div className='flex justify-start gap-x-4'>
            <ButtonIcon
              type='submit'
              content='Test'
              className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary
                hover:text-white'
            />
            <ButtonIcon
              type='button'
              content='Launch'
              className='min-w-28 bg-light-primary text-white hover:brightness-110'
            />
          </div>

          {trial !== null && count !== null && (
            <div>
              <p className='text-sm'>
                <span className='rounded-xl bg-yellow-400 p-1 px-2 text-sm'>{`${trial}/${count}`}</span>{' '}
                Test cards left
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ProgramCard
