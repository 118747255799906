import { FirebaseDb, useDatabaseNullableRefLiveValue } from './components/common/Firebase'
import { useMemo } from 'react'
import {
  ActiveLoyaltyCardEntry,
  PointBalance,
  ScratchyBalance,
  TokenBalance,
} from '../functions/src/data/common'
import { FirebaseError } from 'firebase/app'

export type LoyaltyCardState =
  | {
      type: 'SUCCESS'
      cardData: ActiveLoyaltyCardEntry
      updateTokenBalance: (tokenType: string, fn: (value: TokenBalance) => TokenBalance) => void
      updatePointBalance: (pointType: string, fn: (value: PointBalance) => PointBalance) => void
      updateScratchyBalance: (
        scratchyType: string,
        fn: (value: ScratchyBalance) => ScratchyBalance,
      ) => void
    }
  | { type: 'FAIL'; error: FirebaseError }
  | { type: 'NO_CARD' }
  | { type: 'PENDING' }

export function useLoyaltyCardStore(id: string, firebaseDb: FirebaseDb): LoyaltyCardState {
  const ref = useMemo(() => {
    return firebaseDb.getRef(`/cards/${id}`)
  }, [id, firebaseDb])

  const cardData = useDatabaseNullableRefLiveValue<ActiveLoyaltyCardEntry>({ ref })

  return useMemo(() => {
    if (cardData instanceof Error) {
      return { type: 'FAIL', error: cardData as FirebaseError }
    } else if (cardData === null) {
      return { type: 'NO_CARD' }
    } else if (cardData === undefined) {
      return { type: 'PENDING' }
    } else {
      return {
        type: 'SUCCESS',
        cardData,
        updateTokenBalance: async (
          tokenType: string,
          fn: (value: TokenBalance) => TokenBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${tokenType}`)
            .runTransaction((data: TokenBalance) => fn(data))
        },
        updatePointBalance: async (
          pointType: string,
          fn: (value: PointBalance) => PointBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${pointType}`)
            .runTransaction((data: PointBalance) => fn(data))
        },
        updateScratchyBalance: async (
          scratchyType: string,
          fn: (value: ScratchyBalance) => ScratchyBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${scratchyType}`)
            .runTransaction((data: ScratchyBalance) => fn(data))
        },
      }
    }
  }, [cardData, firebaseDb, id])
}
